//  3 Columns Teaser with cta
//  -----------------------------

.teaser-txt-cta-3-columns {
  text-align: center;
  .h1 {
    margin-bottom: 2.5rem;
    span {
      font-family: museo-500;
    }
    @include breakpoint(medium) {
      margin-bottom: 95px;
    }
  }
  .centered-columns {
    display: flex;
    justify-content: center;
  }
  .teaser-txt-cta-3-columns-box {
    @include breakpoint(medium) {
      min-height: rem-calc(450);
    }
    .teaser-headline-wrap {
      min-height: 68px;
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      @include breakpoint(medium) {
        min-height: 100px;
      }
      h3 {
        display: inline-block;
        margin-bottom: 0;
        padding: rem-calc(20 16);
        @include breakpoint(medium) {
          padding: rem-calc(34 16);
        }
      }
    }
    &.no-minheight {
      @include breakpoint(medium) {
        min-height: rem-calc(325);
      }
      .teaser-content-wrap {
        min-height: initial;
      }
    }
    .teaser-content-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 40px;

      @include breakpoint(medium) {
        min-height: rem-calc(374);
        padding: 30px 40px;
      }

      @include breakpoint(large) {
        padding: 30px 20px;
      }

      @include breakpoint(xlarge) {
        padding: 35px 60px;
      }
      a {
        margin-top: 15px;
        margin-bottom: 10px;
        color: $primary-color;
        align-self: center;
        &:hover {
          color: $white;
        }
        @include breakpoint(medium) {
          margin-bottom: 25px;
          margin-top: 0;
        }
      }
    }
  }
  .button-row {
    margin-top: 30px;
    margin-bottom: 40px;
    @include breakpoint(large) {
      margin-top: 60px;
      margin-bottom: 20px;
    }
    a {
      border-color: $white;
      margin-bottom: 0;
    }
  }
}
