//  Site Header
//  -----------------------------

//  Site Header
//  -----------------------------

.site-header {
  position: fixed;
  width: 100%;
  transition: all 0.25s ease;
  z-index: 2000;

  //Scrolled
  &.scrolled {
    transition: all 0.25s ease;
    top: 0;
    -webkit-box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.15);
    background-color: $white;

    .nav-main {
      li {
        //cursor: pointer;
        &::before {
          display: none;
        }
        a {
          color: $dark-gray;
          &:hover {
            color: $secondary-color;
            &:after {
              background-color: $secondary-color;
            }
          }
        }

        &.active {
          a {
            color: $secondary-color;
            &.is-active {
              &:after {
                background-color: $secondary-color;
              }
            }
          }
          &:after {
            background-color: $secondary-color !important;
          }
        }
      }
    }
    .logo {
      img {
        &.main-state {
          display: none;
        }
        &.scrolled {
          display: block !important;
        }
      }
    }
    .hamburger-inner {
      background-color: $dark-gray !important;
      &:before {
        background-color: $dark-gray !important;
      }
      &:after {
        background-color: $dark-gray !important;
      }
    }
  }
  .desktop {
    display: none;

    @include breakpoint(xlarge) {
      display: block;
    }
    .page-content {
      padding-top: 0;
      padding-bottom: 0;
      .navigation {
        padding: rem-calc(40 0 20);
        position: relative;
        z-index: 200;
        transition: all 0.25s ease;

        &.flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .logo {
          img {
            width: 116px;
            height: auto;

            &.scrolled {
              display: none;
            }
          }
        }

        .nav-main {
          li {
            margin: rem-calc(0 15);
            &:before {
              display: none;
            }
            a {
              padding: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .mobile {
    @include breakpoint(xlarge) {
      display: none;
    }

    .logo {
      img {
        width: 116px;
        height: auto;

        &.scrolled {
          display: none;
        }
      }
    }

    .top {
      padding: rem-calc(40 10 20);
      position: relative;
      z-index: 200;

      @include breakpoint(medium) {
        padding: rem-calc(40 40 20);
      }

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .toggle-menu-navi {
      float: right;
      .hamburger {
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        border: 0;
        margin: 0;
        overflow: visible;
        vertical-align: middle;
        .hamburger-box {
          width: 40px;
          height: 24px;
          display: inline-block;
          position: relative;
          .hamburger-inner {
            display: block;
            margin-top: -2px;
            width: 40px;
            height: 4px;
            background-color: $white;
            border-radius: 4px;
            position: absolute;
            transition-property: transform;
            top: auto;
            bottom: 0;
            transition-duration: 0.13s;
            transition-delay: 0.13s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

            &::before,
            &::after {
              content: "";
              width: 40px;
              height: 4px;
              background-color: $white;
              border-radius: 4px;
              position: absolute;
              transition-property: transform;
              transition-duration: 0.15s;
              transition-timing-function: ease;
            }

            &::before {
              top: -10px;
              transition: top 0.12s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            &::after {
              top: -20px;
              bottom: -10px;
              transition: top 0.2s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                opacity 0.1s linear;
            }
          }
        }
      }

      &.open {
        .hamburger-inner {
          transform: translate3d(0, -10px, 0) rotate(-45deg);
          transition-delay: 0.22s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          &::before {
            top: 0 !important;
            transform: rotate(-90deg);
            transition: top 0.1s 0.16s
                cubic-bezier(0.33333, 0, 0.66667, 0.33333),
              transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
          }

          &::after {
            top: 0 !important;
            opacity: 0;
            transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
              opacity 0.1s 0.22s linear;
          }
        }
      }
    }
  }
}

.nav-main {
  li {
    //cursor: pointer;
    &::before {
      display: none;
    }
    a {
      position: relative;
      @include lato-heavy;
      font-size: rem-calc(13);
      line-height: rem-calc(16);
      color: $white;
      text-transform: uppercase;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        bottom: 6px;
        width: 100%;
        height: 2px;
        background-color: transparent;
        @include trans-bg-color;
        @include breakpoint(xlarge) {
          bottom: -6px;
        }
      }
      &:hover {
        text-decoration: none;
        color: $white;
        &:after {
          background-color: $white;
        }
      }
      &.is-active {
        color: $secondary-color !important;
        &:after {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          bottom: 6px;
          width: 100%;
          height: 2px;
          background-color: $secondary-color;
          @include trans-bg-color;
          @include breakpoint(xlarge) {
            bottom: -6px;
          }
        }
      }
    }

    &.active {
      position: relative;
      background-color: transparent !important;
      &::after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        bottom: 6px;
        width: 100%;
        height: 2px;
        background-color: $white;
        @include trans-bg-color;
        @include breakpoint(xlarge) {
          bottom: -6px;
        }
      }
    }
  }
}

.header-layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $secondary-color;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.45s ease;
  overflow-y: auto;
  min-height: 100vh;

  @include breakpoint(xlarge) {
    display: none;
  }

  &.open {
    transform: translateY(0);
    opacity: 1;
  }

  .mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: scroll !important;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    //min-height: -webkit-stretch;
    @include breakpoint(xlarge) {
      display: none;
    }

    .mobile-navigation {
      text-align: center;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      height: 100%;

      .middle {
        margin-top: 70px;
        @media only screen and (max-height: 414px) {
          margin-top: 40px;
        }
        @include breakpoint(medium) {
          margin-top: 90px;
          @media only screen and (max-height: 414px) {
            margin-top: 40px;
          }
        }
      }

      .nav-main {
        display: inline-block;
        flex-direction: column;
        padding: 0 10px 0 10px;
        li {
          position: relative;
          display: table;
          margin-left: auto;
          margin-right: auto;
          flex: none;
          -ms-flex: none;
          a {
            position: relative;
            display: inline-block;
            text-decoration: none;
            background-color: transparent !important;
            padding: 10px 0;
            @include breakpoint(medium) {
              font-size: rem-calc(20);
              line-height: rem-calc(24);
            }
            &.is-active {
              color: $white !important;
              &:after {
                content: "";
                position: absolute;
                display: block;
                left: 0;
                bottom: 6px;
                width: 100%;
                height: 2px;
                background-color: $white;
                @include trans-bg-color;
              }
            }
          }

          &.active {
            color: $white;
            background-color: transparent !important;
            &:after {
              content: "";
              position: absolute;
              display: block;
              left: 0;
              bottom: 6px;
              width: 100%;
              height: 2px;
              background-color: $white;
              @include trans-bg-color;
            }
          }
        }
      }

      .middle {
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-top: 45px;
        padding-bottom: 45px;
        height: calc(100vh - 213px - 70px);
        display: flex;

        @media only screen and (max-height: 414px) {
          min-height: 320px;
        }

        @include breakpoint(medium) {
          height: calc(100vh - 218px - 90px);
          padding-top: 15vh;
          padding-bottom: 15vh;
        }
      }

      .bottom {
        padding: rem-calc(25 15);
        align-self: flex-end;
        width: 100%;

        @include breakpoint(medium) {
          padding: rem-calc(50 15);
        }

        .headline {
          @extend h4;
          text-transform: none;
          color: $dark-gray;
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          @include breakpoint(medium) {
            flex-direction: row;
          }
          .button {
            display: inline-block;
            margin-bottom: 0;
            margin-left: 12px;
            margin-top: rem-calc(25);
          }
          .textlink {
            margin: 0;
            margin-right: 12px;
            display: inline-block;
            margin-top: rem-calc(25);
          }
        }
      }
    }
  }
}

.no-scroll {
  overflow-x: hidden;

  .site-header {
    @include breakpoint(xlarge down) {
      //background-color: rgba(#fff, 0.95);
      top: 0;
      left: 0;
      right: 0;

      &.scrolled {
        //background-color: rgba(#fff, 0.95);
      }
    }
  }
}
