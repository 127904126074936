//  Data Privacy
//  -----------------------------

.data-privacy {
    .page-content {
        padding: rem-calc(100 10 40);
        @include breakpoint(small) {
            min-height: calc(100vh - 488px);
        }
        @include breakpoint(medium) {
            padding: rem-calc(140 40 80);
            min-height: calc(100vh - 399px);
        }
        @include breakpoint(large) {
          padding: rem-calc(140 80 80);
          min-height: calc(100vh - 365px);
        }
        h2 {
            margin-bottom: 1rem;
            @include breakpoint(small only) {
                line-height: rem-calc(28)
            }
        }
        h4 {
            @include lato-heavy;
            text-transform: none;
            margin-top: 3rem;
            margin-bottom: 1rem;
        }
        ul {
            &.ul-privacy {
                li {
                    color: $white;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}