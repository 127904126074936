.slider-fullscreen {
  .stage-slider-fullscreen {
    @include breakpoint(large) {
      max-height: 800px;
    }
    &.no-max-height {
      max-height: inherit;
    }
    &.ratio-height {
      max-height: inherit;
    }
    .slide {
      position: relative;
      overflow: hidden;
      display: flex !important;
      min-height: 400px;
      @include breakpoint(large) {
        min-height: 800px;
      }
      &.theme-1 {
        .page-content {
          padding-bottom: 80px;
          @include breakpoint(large) {
            @media only screen and (min-height: 580px) {
              position: relative;
            }
          }
          @include breakpoint(xlarge) {
            @media only screen and (min-height: 580px) {
              left: auto !important;
            }
          }
          > .row {
            height: 100%;
          }
          .columns {
            &.text-container {
              margin-top: 0;
              @include breakpoint(large) {
                @media only screen and (min-height: 580px) {
                  margin-top: 15px;
                }
              }
              @include breakpoint(xlarge) {
                @media only screen and (min-height: 580px) {
                  margin-top: rem-calc(100);
                }
              }
            }
            &.kv-container {
              @include breakpoint(small) {
                margin-top: rem-calc(80);
                margin-bottom: rem-calc(40);
              }
              @include breakpoint(medium) {
                margin-top: rem-calc(60);
              }
              @include breakpoint(large) {
                margin-top: 0;
                margin-bottom: 0;
                max-width: $global-width;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                width: 100%;
              }
              .kv-compilation {
                @include breakpoint(medium) {
                  justify-content: flex-end;
                }

                width: 100%;
                @include breakpoint(medium down) {
                  max-width: rem-calc(640);
                }
                img {
                  transition: transform 400ms;
                  width: 100%;
                  height: auto;
                  flex-shrink: 1;
                  &.kv_0 {
                    @include breakpoint(small only) {
                      z-index: 4;
                      //width:639px;
                      max-width: inherit;
                      //transform: translate(-190px, 0);
                    }
                    @include breakpoint(medium up) {
                      display: none;
                    }
                  }
                  &.kv_4 {
                    @include breakpoint(xmedium up) {
                      z-index: 4;
                      max-width: inherit;
                      transform: translate(0px, 0px);
                    }
                    @include breakpoint(small only) {
                      display: none;
                    }
                    @include breakpoint(large up) {
                      display: none;
                    }
                  }
                  &.kv_5 {
                    // @include breakpoint(large) {
                    //   z-index: 4;
                    //   max-width: inherit;
                    //   transform: translate(290px, 0px);
                    //   width: 80%;
                    // }
                    @media only screen and (min-width: 1024px) {
                      z-index: 4;
                      max-width: inherit;
                      transform: translate(230px, 0px);
                      width: 80%;
                    }
                    @media only screen and (min-width: 1300px) {
                      z-index: 4;
                      max-width: inherit;
                      transform: translate(290px, 0px);
                      width: 80%;
                    }
                    @include breakpoint(xmedium down) {
                      display: none;
                    }
                  }
                  /*&.kv_1 {
                    z-index: 3;
                    width: 388px;
                    //height: 180px;
                    transform: translate(300px, 0);
                    -webkit-transform: translate(300px, 0);
                    -moz-transform: translate(300px, 0);

                    @include breakpoint(xmedium down){
                      display:none;
                    }

                    @include breakpoint(medium) {
                      transform: translate(180px, 0);
                      -webkit-transform: translate(180px, 0);
                      -moz-transform: translate(180px, 0);
                    }
                    @include breakpoint(large) {
                      width: 528px;
                      //height: 245px;
                      transform: translate(240px, 0);
                      -webkit-transform: translate(240px, 0);
                      -moz-transform: translate(240px, 0);
                    }
                    @include breakpoint(xxlarge) {
                      width: 588px;
                    }
                  }
                  &.kv_2 {
                    z-index: 1;
                    width: 282px;
                    //height: 317px;
                    transform: translate(140px, -40px);
                    -webkit-transform: translate(140px, -40px);
                    -moz-transform: translate(140px, -40px);
                    @include breakpoint(xmedium down){
                      display:none;
                    }
                    @include breakpoint(medium) {
                      transform: translate(30px, -40px);
                      -webkit-transform: translate(30px, -40px);
                      -moz-transform: translate(30px, -40px);
                    }
                    @include breakpoint(large) {
                      width: 422px;
                      //height: 474px;
                      transform: translate(60px, -40px);
                      -webkit-transform: translate(60px, -40px);
                      -moz-transform: translate(60px, -40px);
                    }
                    @include breakpoint(xxlarge) {
                      width: 482px;
                      //height: 542px;
                      transform: translate(60px, -60px);
                      -webkit-transform: translate(60px, -60px);
                      -moz-transform: translate(60px, -60px);
                    }
                  }
                  &.kv_3 {
                    z-index: 3;
                    width: 160px;
                    //height: 133px;
                    transform: translate(0, 40px);
                    -webkit-transform: translate(0, 40px);
                    -moz-transform: translate(0, 40px);
                    @include breakpoint(xmedium down){
                      display:none;
                    }
                    @include breakpoint(medium) {
                      transform: translate(0, 0);
                      -webkit-transform: translate(0, 0);
                      -moz-transform: translate(0, 0);
                    }
                    @include breakpoint(large) {
                      width: 260px;
                    }
                    @include breakpoint(xxlarge) {
                      width: 320px;
                    }
                  }*/
                }
              }
            }
          }
        }
      }
      &.theme-2 {
        .page-content {
          padding-top: 0;
          padding-bottom: 0;
          .row {
            height: 100%;
            .columns {
              &.text-container {
                max-width: $global-width;
                @include breakpoint(small) {
                  margin-top: rem-calc(120);
                }
                @include breakpoint(large) {
                  margin-top: 120px;
                  @media only screen and (min-height: 580px) {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    width: 100%;
                    margin-top: 0;
                  }
                }
                @include breakpoint(large) {
                  @media only screen and (min-height: 580px) {
                    left: auto;
                  }
                }
                .textlink {
                  &:hover {
                    color: $primary-color;
                    &:after {
                      background-color: $primary-color;
                    }
                  }
                }
              }
              &.kv-container {
                max-width: $global-width;
                margin-top: 50px;
                @include breakpoint(large) {
                  position: absolute;
                  bottom: 0;
                }
                .kv-compilation {
                  justify-content: flex-end;
                  max-width: 450px;
                  margin-left: auto;
                  @include breakpoint(medium) {
                    max-width: 667px;
                    margin-right: 70px;
                  }
                  .kv-compilation {
                    margin-bottom: -40px;
                    @include breakpoint(medium) {
                      margin-bottom: -80px;
                    }
                    @include breakpoint(large) {
                      margin-bottom: 0;
                    }
                    img {
                      @include breakpoint(medium down) {
                        max-height: 400px;
                        margin: 0 auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.theme-3 {
        @include breakpoint(large) {
          min-height: 640px;
          @media only screen and (min-height: 580px) {
            min-height: 800px;
          }
        }
        .page-content {
          padding-top: 0;
          padding-bottom: 60px;
          @include breakpoint(large) {
            padding-bottom: 0;
            @media only screen and (min-height: 580px) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              width: 100%;
            }
          }
          .columns {
            &.text-container {
              max-width: $global-width;
              margin-top: rem-calc(20);
              @include breakpoint(large) {
                margin-top: rem-calc(110);
                @media only screen and (min-height: 580px) {
                  margin-top: rem-calc(15);
                }
              }
            }
            &.kv-container {
              position: relative;
              padding: 60px 0 0 0;
              @include breakpoint(small) {
                margin-top: rem-calc(90);
              }
              @include breakpoint(large) {
                padding: 60px 60px 0 0;
                margin-top: 120px;
              }
              @include breakpoint(xlarge) {
                @media only screen and (min-height: 580px) {
                  margin-top: 0;
                }
              }

              .kv-compilation {
                max-width: 300px;
                @include breakpoint(medium) {
                  max-width: 460px;
                }
                @include breakpoint(large) {
                  justify-content: flex-end;
                  max-width: 600px;
                  margin: 0 auto;
                }
                img {
                  z-index: 2;
                  max-width: 200px;
                  margin-top: 60px;
                  @include breakpoint(medium) {
                    max-width: 350px;
                  }
                  @include breakpoint(large) {
                    max-width: 420px;
                    margin-top: 40px;
                    margin-right: 40px;
                  }
                  @include breakpoint(xlarge) {
                    max-width: unset;
                    max-width: none;
                    margin-top: 0;
                    margin-right: 0;
                  }
                }
                .kv-price {
                  position: absolute;
                  top: -40px;
                  right: 0;
                  width: 210px;
                  height: 210px;
                  padding: rem-calc(40 20);
                  z-index: 1;
                  border-top-left-radius: 50%;
                  border-bottom-right-radius: 50%;
                  border-bottom-left-radius: 50%;
                  @include breakpoint(medium) {
                    width: 260px;
                    height: 260px;
                  }
                  @include breakpoint(large) {
                    width: 260px;
                    height: 260px;
                    top: -50px;
                    right: -50px;
                  }
                  .title {
                    @include museo-900;
                    font-size: rem-calc(18);
                    text-align: right;
                    margin-bottom: 0;
                    @include breakpoint(medium) {
                      font-size: rem-calc(22);
                    }
                  }
                  .price {
                    p {
                      @include museo-700;
                      font-size: rem-calc(80);
                      line-height: 1;
                      text-align: right;
                      margin-bottom: 0;
                      margin-top: -5px;
                      @include breakpoint(medium) {
                        font-size: rem-calc(120);
                        margin-right: -30px;
                      }
                      sup {
                        top: rem-calc(-30);
                        left: rem-calc(-15);
                        font-size: rem-calc(40);
                        letter-spacing: -0.01em;
                        @include breakpoint(medium) {
                          font-size: rem-calc(80);
                          left: rem-calc(-30);
                        }
                      }
                    }
                    .unit {
                      @include museo-300;
                      font-size: rem-calc(12);
                      float: right;
                      margin-top: -30px;
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.theme-4 {
        .page-content {
          padding: 2.5rem 0.625rem 60px;
          @include breakpoint(medium) {
            padding: 5rem 2.5rem;
          }
          @include breakpoint(large) {
            padding: 7.5rem 5rem;
          }
          .row {
            height: 100%;
            .columns {
              &.text-container {
                max-width: $global-width;
                margin-top: 0;

                .teaser-txt {
                  p {
                    max-width: 420px;
                  }
                }

                .kv-compilation {
                  position: relative;
                  max-width: 300px;
                  transform: translateX(-10px);
                  -webkit-transform: translateX(-10px);
                  -moz-transform: translateX(-10px);
                  @include breakpoint(medium) {
                    max-width: 410px;
                    transform: translateX(-30px);
                    -webkit-transform: translateX(-30px);
                    -moz-transform: translateX(-30px);
                  }
                  @include breakpoint(xlarge) {
                    justify-content: flex-end;
                    max-width: 600px;
                    margin-top: 40px;
                  }
                  img {
                    position: relative;
                    display: inline-block;
                    max-width: 200px;
                    margin-top: 60px;
                    z-index: 2;
                    @include breakpoint(small only) {
                      margin-top: 90px;
                    }
                    @include breakpoint(medium) {
                      max-width: 350px;
                    }
                    @include breakpoint(xlarge) {
                      max-width: unset;
                      max-width: none;
                      margin-top: 0;
                    }
                  }
                  .kv-price {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    width: 210px;
                    height: 210px;
                    padding: rem-calc(40 20);
                    border-top-left-radius: 50%;
                    border-bottom-right-radius: 50%;
                    border-bottom-left-radius: 50%;
                    @include breakpoint(small only) {
                      top: 5px;
                      right: -10px;
                    }
                    @include breakpoint(xlarge) {
                      top: -40px;
                      width: 260px;
                      height: 260px;
                    }
                    .title {
                      @include museo-900;
                      font-size: rem-calc(18);
                      text-align: right;
                      margin-bottom: 0;
                      @include breakpoint(xlarge) {
                        font-size: rem-calc(22);
                      }
                    }
                    .price {
                      p {
                        @include museo-700;
                        font-size: rem-calc(80);
                        line-height: 1;
                        text-align: right;
                        margin-bottom: 0;
                        margin-top: -5px;
                        @include breakpoint(xlarge) {
                          font-size: rem-calc(120);
                          margin-right: -30px;
                        }
                        sup {
                          top: rem-calc(-30);
                          left: rem-calc(-15);
                          font-size: rem-calc(40);
                          letter-spacing: -0.01em;
                          @include breakpoint(xlarge) {
                            font-size: rem-calc(80);
                            left: rem-calc(-30);
                          }
                        }
                      }
                      .unit {
                        @include museo-300;
                        font-size: rem-calc(12);
                        float: right;
                        margin-top: -30px;
                        display: block;
                      }
                    }
                  }
                }
              }
              &.kv-container {
                max-width: $global-width;
                margin-top: 30px;
                position: absolute;
                bottom: 0;
                @media only screen and (max-width: 520px) {
                  display: none;
                }
                .kv-compilation {
                  justify-content: flex-end;
                  max-width: 320px;
                  margin-left: auto;
                  @include breakpoint(medium) {
                    max-width: 474px;
                  }
                  @include breakpoint(xmedium) {
                    max-width: 652px;
                  }
                  @include breakpoint(large) {
                    max-width: 750px;
                  }
                  @include breakpoint(xlarge) {
                    max-width: 850px;
                  }
                  .kv_1 {
                    width: auto;
                    @include breakpoint(medium down) {
                      max-height: 480px;
                      transform: translateX(60px);
                      -webkit-transform: translateX(60px);
                      -moz-transform: translateX(60px);
                    }
                    @include breakpoint(medium) {
                      transform: translateX(180px);
                      -webkit-transform: translateX(180px);
                      -moz-transform: translateX(180px);
                    }

                    @include breakpoint(xmedium) {
                      max-height: 660px;
                      transform: translateX(220px);
                      -webkit-transform: translateX(220px);
                      -moz-transform: translateX(220px);
                    }
                    @include breakpoint(large) {
                      max-height: 760px;
                    }
                    @include breakpoint(xlarge) {
                      max-height: 860px;
                      transform: translateX(280px);
                      -webkit-transform: translateX(280px);
                      -moz-transform: translateX(280px);
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.theme-5 {
        display: inline-block !important;
        @include breakpoint(large) {
          min-height: 600px;
        }
        @include breakpoint(xlarge) {
          min-height: 800px;
        }
        .img-wrapper {
          background-image: url("../img/kv_woman.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          min-height: 400px;
          overflow: hidden;
          @include breakpoint(large) {
            min-height: 600px;
          }
          @include breakpoint(xlarge) {
            min-height: 800px;
          }
        }
        .page-content {
          padding-top: 15px;
          @include breakpoint(large) {
            position: absolute;
            top: 0;
            margin-top: 180px;
            @media only screen and (min-height: 580px) {
              margin-top: 0;
              top: 50%;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              padding-top: 0;
              padding-bottom: 0;
            }
          }
          .row {
            height: 100%;
            .columns {
              &.text-container {
                max-width: $global-width;
                .teaser-txt {
                  position: relative;
                  h1,
                  .h1 {
                    @include museo-500;
                    position: relative;
                    z-index: 1;
                    color: $primary-color;
                    margin-bottom: 10px;
                  }
                  p {
                    @include lato-heavy;
                    color: $secondary-color;
                    margin-bottom: 0;
                  }
                  &:before {
                    @include breakpoint(small only) {
                      top: -30px;
                      @include image-svg(
                        "icons/quote-fullwidth-quotation",
                        100px,
                        65px
                      );
                    }
                    content: "";
                    position: absolute;
                    left: 30px;
                    top: -37px;
                    @include image-svg(
                      "icons/quote-fullwidth-quotation",
                      135px,
                      88px
                    );
                    z-index: 1;
                  }
                }
              }
            }
          }
        }
      }
      &.theme-6 {
        min-height: 1px !important;
        .page-content {
          padding: 2.5rem 0.625rem;
          text-align: center;

          @include breakpoint(medium) {
            padding: 5rem 2.5rem;
          }
          @include breakpoint(large) {
            padding: 7.5rem 5rem;
          }
          .row {
            height: 100%;
            .columns {
              &.text-container {
                max-width: $global-width;
                .teaser-txt {
                  position: relative;
                  h1,
                  .h1 {
                    margin-bottom: 27px;
                  }
                  p {
                    @include museo-300;
                    font-size: rem-calc(24);
                    line-height: rem-calc(32);
                    color: $primary-color;
                    margin-bottom: 0;
                    @include breakpoint(medium) {
                      font-size: rem-calc(30);
                      line-height: rem-calc(38);
                    }
                    &.signatur {
                      @include lato-reg;
                      margin-top: rem-calc(32);
                      font-size: rem-calc(10);
                      line-height: 1.5;
                      text-transform: uppercase;
                      color: $white;
                      margin-bottom: rem-calc(40);
                      @include breakpoint(medium) {
                        margin-top: rem-calc(55);
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.theme-7 {
        @include breakpoint(large) {
          min-height: 640px;
          @media only screen and (min-height: 580px) {
            min-height: 800px;
          }
        }
        .page-content {
          padding-top: 0;
          padding-bottom: 60px;
          @include breakpoint(large) {
            padding-bottom: 0;
            @media only screen and (min-height: 580px) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              width: 100%;
            }

            > .row {
              position: relative;
            }
          }
          .columns {
            &.text-container {
              max-width: $global-width;
              margin-top: rem-calc(20);
              @include breakpoint(large) {
                margin-top: rem-calc(110);
                @media only screen and (min-height: 580px) {
                  margin-top: rem-calc(15);
                }
              }
            }
            &.kv-container {
              position: relative;
              padding: 60px 0 0 0;
              @include breakpoint(small) {
                margin-top: rem-calc(90);
              }
              @include breakpoint(large) {
                margin: 0;
                padding: 0;
                max-width: none;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transform: translate(0%, 0%);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
              }
              @include breakpoint(xlarge) {
                @media only screen and (min-height: 580px) {
                  margin-top: 0;
                }
              }

              .kv-compilation {
                max-width: 300px;
                @include breakpoint(medium) {
                  max-width: 460px;
                }
                @include breakpoint(large) {
                  width: 55%;
                  max-width: none;
                  min-width: inherit;
                  padding: 0;
                  padding-right: 60px;
                  position: absolute;
                  right: 0;
                  top: 24%;
                }
                @include breakpoint(xlarge) {
                  display: block;
                  max-width: none;
                  margin: 0;
                  width: 800px;
                  padding-right: 210px;
                  position: absolute;
                  right: -30px;
                  top: 15%;
                }
                @include breakpoint(xxlarge) {
                  right: 20px;
                }
                img {
                  z-index: 2;
                  max-width: 250px;
                  margin-top: 110px;
                  @include breakpoint(medium) {
                    max-width: 350px;
                  }
                  @include breakpoint(large) {
                    margin-left: auto;
                    display: block;
                    max-width: 619px;
                    width: 100%;
                  }
                  @include breakpoint(xlarge) {
                    right: 250px;
                  }
                }
                .kv-price {
                  position: absolute;
                  top: -40px;
                  right: 0;
                  width: 210px;
                  height: 210px;
                  padding: rem-calc(50 20);
                  z-index: 1;
                  border-top-left-radius: 50%;
                  border-bottom-right-radius: 50%;
                  border-bottom-left-radius: 50%;
                  @include breakpoint(medium) {
                    width: 260px;
                    height: 260px;
                    padding: rem-calc(65 20);
                  }
                  @include breakpoint(large) {
                    width: 260px;
                    height: 260px;
                    top: -120px;
                    right: 0px;
                    z-index: -1;
                  }
                  @include breakpoint(xlarge) {
                    top: -10px;
                  }
                  .title {
                    @include museo-900;
                    font-size: rem-calc(18);
                    text-transform: uppercase;
                    text-align: right;
                    margin-bottom: 0;
                    @include breakpoint(medium) {
                      font-size: rem-calc(22);
                    }
                  }
                  .price {
                    @include breakpoint(medium) {
                      padding-left: 13px;
                    }

                    p {
                      @include museo-700;
                      font-size: rem-calc(84);
                      line-height: 1;
                      text-align: right;
                      margin-bottom: 0;
                      margin-top: -5px;
                      @include breakpoint(medium) {
                        @include museo-900;
                        font-size: rem-calc(96);
                        margin-right: -30px;
                      }
                      sup {
                        top: rem-calc(-30);
                        left: rem-calc(-18);
                        font-size: rem-calc(42);
                        letter-spacing: -0.01em;
                        @include breakpoint(medium) {
                          font-size: rem-calc(54);
                          left: rem-calc(-26);
                        }
                      }
                    }
                    .unit {
                      @include museo-300;
                      font-size: rem-calc(12);
                      float: right;
                      margin-top: -29px;
                      display: block;
                      @include breakpoint(medium) {
                        font-size: rem-calc(16);
                        margin-top: -35px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.theme-8 {
        position: relative;
        min-height: initial !important;
        flex-direction: column;
        @include breakpoint(large) {
          flex-direction: row;
        }

        &.maintenance {
          .teaser-txt {
            @include breakpoint(large) {
              padding: 0 !important;
              &::after {
                display: none !important;
              }
            }
            ul {
              @include breakpoint(large) {
                color: $secondary-color !important;
                li {
                  &:before {
                    color: $secondary-color !important;
                  }
                }
              }
            }
          }
        }

        .image {
          position: relative;
          display: block;
          overflow: hidden;
          width: 100%;
          padding-top: 100%;
          @include breakpoint(medium) {
            padding-top: 75%;
          }
          @include breakpoint(large) {
            min-height: 800px;
            padding-top: 56.25%;
          }
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            height: 100%;
            width: auto;
            max-width: initial;
            transform: translateX(-50%);
          }
        }

        .text-container {
          display: block;
          width: 100%;
          @include breakpoint(large) {
            position: absolute;
            height: 100%;
          }

          .row {
            position: relative;
            width: 100%;

            .teaser-txt {
              position: relative;
              padding: rem-calc(40 20);
              @include gradient-primary;

              @include breakpoint(medium) {
                padding: rem-calc(80 60);
              }

              @include breakpoint(large) {
                position: absolute;
                top: 120px;
                left: 0;
                z-index: 1;
                padding: rem-calc(80);
                margin-left: 60px;
                background: none;
                @media only screen and (min-height: 580px) {
                  top: 230px;
                }
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  @include gradient-secondary-linear;
                  opacity: 0.85;
                  z-index: -1;
                }
              }

              @include breakpoint(xlarge) {
                margin-left: 100px;
              }

              @include breakpoint(xxlarge) {
                margin-left: 20px;
              }

              h1 {
                color: $white;
                margin-bottom: 10px;
                @include breakpoint(medium) {
                  margin-bottom: 20px;
                }
                &:before {
                  background-color: $white;
                }
              }
              ul {
                color: $secondary-color;
                li {
                  &:before {
                    color: $secondary-color;
                  }
                }
                @include breakpoint(large) {
                  color: $white;
                  li {
                    &:before {
                      color: $white;
                    }
                  }
                }
              }
              a {
                color: $white;
                margin-bottom: 0;
                margin-top: 8px;
                @include breakpoint(medium) {
                  margin-top: 12px;
                }
                &:after {
                  background-color: $white;
                }
                &:hover {
                  color: $primary-color;
                  &:after {
                    background-color: $primary-color;
                  }
                }
              }
            }
          }
        }
      }
      .page-content {
        width: 100%;
        .columns {
          &.text-container {
            margin-top: 0;
            .teaser-txt {
              h1,
              .h1 {
                margin-bottom: 16px;
                @include breakpoint(medium) {
                  margin-bottom: 24px;
                }
                span {
                  color: $secondary-color;
                }
              }
              ul {
                margin-bottom: 0;
              }
              .textlink {
                margin-top: rem-calc(22);
                margin-bottom: 0;
                @include breakpoint(medium) {
                  margin-top: rem-calc(25);
                }
              }
              .button {
                margin-top: rem-calc(20);
                margin-bottom: 0;
              }
            }
            .text-footer {
              margin-top: 40px;
              .icon {
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                margin-top: 0;
              }
              // .icon {
              //   display: block;
              //   margin-top: 10px;
              // }
              p {
                margin: 0;
              }
              // @include breakpoint(medium) {
              //   .icon {
              //     display: inline-block;
              //     vertical-align: middle;
              //     margin-left: 10px;
              //     margin-top: 0;
              //   }
              // }
              @include breakpoint(large) {
                margin-top: 120px;
              }
            }
          }
          &.kv-container {
            pointer-events: none;
            .kv-compilation {
              position: relative;
              //display: flex;
              align-items: baseline;
              width: 100%;
              img {
                display: inline-block;
                transition: transform 400ms;
                -webkit-transition: transform 400ms;
              }
            }
          }
        }
      }
    }
    ul {
      &.slick-dots {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        text-align: center;
        padding-bottom: 30px;
        z-index: 2;
        width: 100%;
        bottom: 0;
        @include breakpoint(large) {
          padding-bottom: 60px;
        }
        li {
          display: inline-block;
          line-height: unset;
          cursor: pointer;
          width: 4px;
          height: 4px;
          margin: 3px;
          &:before {
            display: none;
          }
          button {
            position: absolute;
            display: block;
            cursor: pointer;
            font-size: 0;
            border-radius: 100%;
            width: 4px;
            height: 4px;
            padding: 0;
            border: none;
            background-color: $white;
            &:hover {
              background-color: $secondary-color;
            }
          }
          &.slick-active {
            width: 8px;
            height: 8px;
            button {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
    &.about-us-quotes {
      @include breakpoint(large only) {
        max-height: 600px;
      }
      h1 {
        font-size: rem-calc(38);
        line-height: rem-calc(42);
      }
      ul {
        &.slick-dots {
          top: calc(400px - 30px);
          bottom: auto;
          @include breakpoint(large) {
            top: auto;
            bottom: 0;
            padding-bottom: 60px;
          }
        }
      }
    }
  }
}
