//  Site Footer
//  -----------------------------

.site-footer {
  min-height: $footer-min-height;

  .row {
    .columns {
      @include breakpoint(medium down) {
        padding-bottom: 50px;
      }

      &:nth-child(2) {
        padding-bottom: 0;
      }

      img {
        width: 195px;
        height: auto;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 0;
      }

      .contact-mobile {
        display: block;
        @include breakpoint(large) {
          display: none;
        }
        + .contact-mobile {
          margin-top:20px;
          margin-bottom: 50px;
        }
      }

      &.contact {
        display: none;
        @include breakpoint(large) {
          display: block;
        }
        p{
          + p{
            margin-top:20px;
          }
        }
      }

      nav {
        display: flex;
        align-items: flex-start;
        ul {
          margin: 0;
          &[data-magellan] {
            margin-right: 60px;
            @include breakpoint(xlarge) {
              margin-right: 80px;
            }
          }

          li {
            margin-bottom: 3px;
            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              display: none;
            }
            a {
              position: relative;
              &.is-active {
                &:after {
                  background-color: $white;
                }
              }
              &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                background-color: unset;
                @include trans-bg-color;
              }
              &:hover {
                text-decoration: none;
                color: $secondary-color;
                &:after {
                  background-color: $secondary-color;
                }
              }
            }
            &.active {
              a {
                &:after {
                  content: "";
                  position: absolute;
                  left: 0;
                  bottom: -2px;
                  width: 100%;
                  height: 2px;
                  background-color: $white;
                  @include trans-bg-color;
                }
                &:hover {
                  &:after {
                    background-color: $secondary-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
