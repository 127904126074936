.banner-box {
  position: fixed;
  width: 100%;
  max-width: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 9999;
  @include breakpoint(xmedium) {
    max-width: 750px;
  }
  &:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #f39200;
    position: absolute;
    top: -20px;
    right: -30px;
    z-index: -1;
    @include breakpoint(medium) {
      top: -40px;
      right: -40px;
    }
  }
  h2,
  .h1 {
    color: #f39200 !important;
  }
  .bg-primary {
    padding: 20px 30px;
    border: 1px solid #f39200;
    @include breakpoint(medium) {
      padding: 50px 100px;
    }
  }
  button {
    color: $white;
    border: 1px solid $white;
    cursor: pointer;
  }
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    font-size: 24px;
  }
  .img-wrapper {
    display: flex;
    width: 100%;
    margin: 25px 0;
    img {
      max-width: 200px;
      margin-left: auto;
      height: 20px;
      @include breakpoint(large) {
        height: auto;
      }
    }
  }
  .text-overflow {
    overflow-y: scroll;
    max-height: 190px;
    @include breakpoint(medium) {
      overflow-y: auto;
      max-height: none;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    .btn-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @include breakpoint(large) {
        margin-top: 25px;
      }
      p {
        margin-top: 25px;
        margin-bottom: 0;
        @include breakpoint(large) {
          margin-top: 0;
        }
      }
    }
    img {
      display: none;
      max-width: 300px;
      @include breakpoint(large) {
        display: block;
      }
    }
  }  
}
.banner-box.hidden {
  display: none;
}
