.disturber-container {
  display: flex;
  flex-direction: column-reverse;
  border-bottom: solid 2px $white;
  // background-color: $dark-pink;
  // background-image: radial-gradient(circle, #e5005b 0%, #e4032e 100%);
  @include breakpoint(large) {
    flex-direction: row;
  }
  &.blue {
    background-color: #0e4e8b;
  }

  .page-content{
    padding:0;
    width: 100%;
    order: 2;
    @include breakpoint(medium) {
      padding: rem-calc(0 40);
   }
   @include breakpoint(large) {
     padding: rem-calc(0 80);
     order: 1;
   }
  .columns{
    .disturber {
      p {
        @include museo-500; //@include museo-700;
        color: $white;
        font-size: rem-calc(18); //font-size: rem-calc(16);
        line-height: rem-calc(21);
        margin-bottom: 0;
        padding: rem-calc(20 0);
        text-align: center;

        @include breakpoint(large) {
          text-align: left;
          min-height: 65px;
          max-width: calc(100% - 200px);
          padding: rem-calc(24 0 16);
          font-size: rem-calc(20);
        }

        @media only screen and (min-width: 1120px) {
          padding: rem-calc(24 0 16 0);
          max-width: none;
        }

        .orange {
          color: $secondary-color;
        }
        .green {
          color: #98c41e;
        }
    
        a {
          color: $white;
          @include museo-700;
          font-size: rem-calc(16);
          line-height: rem-calc(21);
          text-decoration: underline;
        }

        span {
          display: block;
          text-align: left;
          @include breakpoint(medium) {
            display: inline-block;
          }
          
          &.font-fat {
            @include museo-900;
          }

          &.mobile-only {
            @include breakpoint(large) {
              display: none;
            }
          }
           
          &.desktop-only {
            display: none;
            @include breakpoint(large) {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
    .img-wrapper {
      background-color: $white;
      padding: rem-calc(10 0);
      display: flex;
      align-items: center;
      justify-content: center;
      order: 1;

      @include breakpoint(large) {
        position: absolute;
        right: 0;
        top: 0;
        min-height: 65px;
        width: 260px; // 190px; //width: 200px;
        padding: 0;
        order: 2;
        background-color: transparent;
      }

      @media only screen and (min-width: 1024px) {
        min-height: 82px;
      }

      @media only screen and (min-width: 1120px) {
        min-height: 65px;
      }

      img {
        width: 285px; //148px;
        height: auto;
      }
    }
  }
