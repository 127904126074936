.img-and-txt {
  position: relative;

  .absolute-label {
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    z-index: 2;

    .row {
      .column {
        .row {
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          .column-inside {
            width: 100%;
            padding: 0 20px !important;
            text-align: right;
            @include breakpoint(medium) {
              padding: 0 50px !important;
            }
            @include breakpoint(large) {
              text-align: left;
              padding: 0 !important;
            }
            .label {
              background-color: $secondary-color;
              color: $white;
              text-transform: uppercase;
              font-size: rem-calc(13);
              line-height: 1;
              padding: 8px 40px;
            }
          }
        }
      }
    }

    &.on-left {
      .row {
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
      }
    }
    &.on-right {
      .row {
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        -ms-justify-content: flex-end;
        justify-content: flex-end;
      }
    }
  }

  .row {
    position: relative;

    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-box-pack: center;
    -webkit-box-align: center;
    -moz-box-pack: center;
    -moz-box-align: center;
    -ms-box-pack: center;
    -ms-box-align: center;
    align-items: center;

    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    @include breakpoint(large) {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .column {
      float: none;
      -moz-flex: 0 0 100%;
      -webkit-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;

      @include breakpoint(large) {
        -moz-flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      &.img-column {
        padding: 0;
        .img-container {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding-top: 66.66%; /* 3:2 Aspect Ratio */
          @include breakpoint(large) {
            padding-top: 100%; /* 1:1 Aspect Ratio */
          }
          img {
            position: absolute;
            display: block;
            top: 0;
            left: 50%;
            bottom: 0;
            width: 100%;
            height: auto;
            max-width: initial;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            @include breakpoint(large) {
              width: auto;
              height: 100%;
            }
          }
        }
      }
      &.txt-column {
        .row {
          max-width: $global-width;
          .column-inside {
            -moz-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            padding: 50px 20px;
            @include breakpoint(medium) {
              padding: 50px;
            }
            @include breakpoint(large) {
              padding: 0;
            }

            @include breakpoint(large) {
              -moz-flex: 0 0 83.33333%;
              -webkit-flex: 0 0 83.33333%;
              flex: 0 0 83.33333%;
              max-width: 83.33333%;
            }

            @include breakpoint(xlarge) {
              -moz-flex: 0 0 calc(66.66667% + 40px);
              -webkit-flex: 0 0 calc(66.66667% + 40px);
              flex: 0 0 calc(66.66667% + 40px);
              max-width: calc(66.66667% + 40px);
            }

            .statement {
              color: $primary-color;
              margin-bottom: 25px;
              @include breakpoint(large) {
                margin-bottom: 50px;
              }
              &::before {
                content: "";
                display: block;
                width: 60px;
                height: 5px;
                background-color: $primary-color;
                margin-bottom: 9px;
                @include breakpoint(medium) {
                  margin-bottom: 15px;
                }
              }
            }

            .textlink {
              color: $primary-color;
              margin-bottom: 0;
              margin-top: 8px;
              @include breakpoint(large) {
                margin-top: 28px;
              }
            }
          }
        }
      }
    }
  }

  &.reverse-order {
    .column {
      &.txt-column {
        -webkit-order: 2;
        order: 2;
        @include breakpoint(large) {
          -webkit-order: 1;
          order: 1;
        }
      }
      &.img-column {
        -webkit-order: 1;
        order: 1;
        @include breakpoint(large) {
          -webkit-order: 2;
          order: 2;
        }
      }
    }
  }

  &.maintenance,
  &.training {
    .statement {
      color: $white !important;
      @include breakpoint(large) {
        margin-bottom: 15px !important;
      }
      @include breakpoint(xlarge) {
        margin-bottom: 25px !important;
      }
      &::before {
        background-color: $white !important;
      }

      font-size: 24px;
      @include breakpoint(large) {
        font-size: 36px;
      }
      @include breakpoint(xlarge) {
        font-size: 48px;
      }
    }

    .textlink {
      position: relative;
      color: $white !important;
      margin-top: 8px !important;
    }
  }

  &.training {
    img {
      width: auto !important;
      height: 100% !important;
    }
  }
}
