//  Form
//  -----------------------------
//
//  Table of Contents:
//
//   1. Textfield/Textarea
//   2. Select/Chosen
//   3. Checkbox
//   4. Radio

form {
    label{
        color:$white;
    }
    input[type="text"],input[type="email"], textarea {
        border-bottom:2px solid $white;
        color:$white;
        //background-color: unset!important;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        &:focus{
            border-bottom:2px solid $white;
        }
    }
    .container-radio {
        input {
            /* When the checkbox is checked */
            &:checked {
                ~ .checkmark {
                    border-color: $secondary-color; 
                    color: $secondary-color;
                    background-color: $secondary-color;

                    /* Show the checkmark when checked */
                    &:after {
                        display: block;
                    }
                }
            }

            /* Error */
            &.error {
                ~ .checkmark {
                    background-color: $secondary-color;

                    /* Show the checkmark when checked */
                    &:after {
                        background-color: $alert-color;
                        display: none;
                    }
                }
            }
        }
        /* On mouse-over */
        &:hover {
            input {
                ~ .checkmark {
                    border-color: $secondary-color;
                    &:after {
                        background-color: $secondary-color;
                    }
                }

                &:checked {
                    ~ .checkmark {
                        background-color: $secondary-color;
                        border-color: $secondary-color;
                        &:after {
                            background-color: $white;
                        }
                    }
                }
            }
        }

        /* Style the checkmark/indicator */
        .checkmark {
            border-color: $white;

            &:after {
                background-color: $white;
            }
        }

    }

    // 1. Textfield/Textarea
    // ---------

    // input[type="text"], textarea {     

    //     @include placeholder {

    //     }

    //     &.error {

    //     }
    // }

    input[type="text"],input[type="email"], textarea {

        transition: all 0.25s ease-in-out;

        @include placeholder {
            font-family: inherit;
            font-size: rem-calc(16);
            color: $white;
        }

        &.error {
            border-color: $secondary-color;
            color: $secondary-color;
        }

        &:focus{
            border-color: $secondary-color;
        }
    }

    textarea {
        border-left: none;
        border-right: none;
        border-top: none;
        min-height: 132px;
        resize: none;
        padding-top: rem-calc(20);

        &::placeholder {
            opacity:0;
        }
        &::-webkit-input-placeholder{
            opacity:0; 
        }
        &::-ms-input-placeholder{
            opacity:0; 
        }

        &:focus{
            border-left: none;
            border-right: none;
            border-top: none; 
            &::placeholder {
                opacity:1;
            }
            &::-webkit-input-placeholder{
                opacity:1; 
            }
            &::-ms-input-placeholder{
                opacity:1; 
            }
        }
    }


    hr{
        margin-top: rem-calc(60);
        margin-bottom: rem-calc(60);
    }

    .form-wrapper{
        position:relative;
        margin-bottom: 35px;

        input:-internal-autofill-selected {
            //background-color: unset!important;
            background-color: transparent;
            background-image: none!important;
            color: $white!important;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          -webkit-text-fill-color: $white;
          -webkit-box-shadow: 0 0 0px 0px $primary-color inset;
          transition: background-color 5000s ease-in-out 0s;
        }

        input[type="text"],input[type="email"], textarea{
            margin-bottom: 0;
        }

        .label-textarea{
            top: 20px;
        }

        label{
            position: absolute;
            top:1px;
            z-index: 10;
            transition: transform 150ms ease-out, font-size 150ms ease-out;
            pointer-events: none;
        }

        &.focused {
            label {
                transform: translateY(-125%);
                font-size:rem-calc(12);
            }

            .message{
                &.message-info{
                    display: block;
                }
            }
        }

        .message{
            display: none;
            color: $white;
            font-size: rem-calc(12);
            padding: rem-calc(12 10);
            padding-bottom: 0;
            text-align: right;
        }

        &.error{ 
            input[type="text"],input[type="email"], textarea{
                background-color: $white;
                border-bottom: none;
                color:#ff0000;  
            }
            &.focused {
                label{
                    color: $white;
                    top: 2px;
                    span {
                        color: $white;
                    }
                }
            }

            label{
                color: #ff0000;
                top: 7px;
                left: 8px;
                span {
                    color: #ff0000;
                }
            }

            .message{
                color: $secondary-color;
                padding-bottom:rem-calc(12);

                &.message-info{
                    display: none;
                }
            }
        }
    }

    label{
        color:$white;
        font-family: inherit;
        font-size: rem-calc(16);
        line-height: 1.4;
    }

    .gender{
        label{
            margin-top:0;
            margin-bottom:20px;
            margin-left:0;
        }
        div{
            display:inline-block;
            margin-right:rem-calc(30);
            &:last-child{
                margin-right:0;  
            }
            @include breakpoint(small only){
                display:block;  
            }
        }
    }

    input[type="text"],input[type="email"], textarea {
        //background-color:unset!important;
        background-color: transparent;
        border-top:none;
        border-right:none;
        border-left:none;
        border-bottom:2px solid $white;
        color:$white;
        font-family: inherit;
        box-shadow:none;
        transition:none;
        font-size:rem-calc(16);
        padding:rem-calc(0 0 12);
        height:auto;
        &:focus{
            //background-color:unset!important;
            background-color: transparent;
            border-top:none;
            border-right:none;
            border-left:none;
            border-bottom:2px solid $secondary-color;
            box-shadow:none;
        }
    }
    

    // 2. Select/Chosen
    // ---------

    // select {

    //     &.error {

    //     }
    // }
    
    // 3. Checkbox
    // ---------

    /* Container */
    .container-checkbox {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        //add transition and styles
        padding-left: 35px; //Width of clickarea + margin
        margin-bottom: 10px;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            margin: 0;

            /* When the checkbox is checked */
            &:checked {
                ~ .checkmark {
                    border-color: $secondary-color;
                    color: $secondary-color;

                    /* Show the checkmark when checked */
                    &:after {
                        display: block;
                    }
                }
            }

            /* Error */
            &.error {
                ~ .checkmark {
                    border-color: $alert-color;
                    color: $alert-color;
                    &:after {
                        background-color: transparent;
                    }
                }
            }
        }

        /* On mouse-over */
        &:hover {
            input {
                ~ .checkmark {
                    border-color: $primary-color;
                    color: $primary-color;
                }

                &:checked {
                    ~ .checkmark {
                        border-color: $primary-color;
                        color: $primary-color;
                    }
                }
            }
        }

        /* Style the checkmark/indicator */
        .checkmark {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0%, -50%);
            margin-top: 0;
            height: 22px;
            width: 22px;
            background-color: $white;
            border: 1px solid $primary-color;
            border-radius: 0;

            &:after {
                content: "";
                position: absolute;
                display: none;

                //Add Icon + Position
                left: 4px;
                top: 0;
                width: 12px;
                height: 12px;
                background-color: §primary-color;
            }
        }
    }

    // 4. Radio
    // ---------

    /* Container */
    .container-radio {
        display: block;
        position: relative;
        cursor: pointer;
        border-radius: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        //add transition and styles
        padding-left: 35px; //Width of clickarea + margin
        margin-bottom: 10px;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            margin: 0;

            /* When the checkbox is checked */
            &:checked {
                ~ .checkmark {
                    border-color: $secondary-color;
                    color: $secondary-color;

                    /* Show the checkmark when checked */
                    &:after {
                        display: block;
                        background-color: $secondary-color;
                    }
                }
            }

            /* Error */
            &.error {
                ~ .checkmark {
                    border-color: $alert-color;
                    color: $alert-color;

                    /* Show the checkmark when checked */
                    &:after {
                        background-color: $alert-color;
                        display: none;
                    }
                }
            }
        }

        /* On mouse-over */
        &:hover {
            input {
                ~ .checkmark {
                    border-color: $primary-color;
                    color: $primary-color;

                    &:after {
                        background-color: $primary-color;
                    }
                }

                &:checked {
                    ~ .checkmark {
                        border-color: $primary-color;
                        color: $primary-color;

                        &:after {
                            background-color: $primary-color;
                        }
                    }
                }
            }
        }

        /* Style the checkmark/indicator */
        .checkmark {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0%, -50%);
            margin-top: 0;
            border-radius: 100%;

            //Style outer circle
            height: 22px;
            width: 22px;
            background-color: $white;
            border: 1px solid $primary-color;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 100%;

                //Style inner circle
                width: 10px;
                height: 10px;
                background-color: $primary-color;
            }
        }
    }

    // 3.+4. Checkbox + Radio
    // ---------

    /* Container */
    .container-radio, .container-checkbox {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        //add transition and styles
        padding-left: 35px; //Width of clickarea + margin
        margin-bottom: 10px;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            margin: 0;

            /* When the checkbox is checked */
            &:checked {
                ~ .checkmark {
                    border-color: $secondary-color;
                    color: $secondary-color;

                    /* Show the checkmark when checked */
                    &:after {
                        display: block;
                    }
                }
            }

            /* Error */
            &.error {
                ~ .checkmark {
                    border-color: $alert-color;
                    color: $alert-color;

                    /* Show the checkmark when checked */
                    &:after {
                        background-color: $alert-color;
                        display: none;
                    }
                }
            }
        }

        /* On mouse-over */
        &:hover {
            input {
                ~ .checkmark {
                    border-color: $secondary-color;
                    color: $secondary-color;

                    &:after {
                        background-color: $secondary-color;
                    }
                }

                &:checked {
                    ~ .checkmark {
                        border-color: $secondary-color;
                        color: $secondary-color;

                        &:after {
                            background-color: $secondary-color;
                        }
                    }
                }
            }
        }

        /* Style the checkmark/indicator */
        .checkmark {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0%, -50%);
            margin-top: 0;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    // 3. Checkbox
    // ---------

    .container-checkbox {
        /* Style the checkmark/indicator */
        .checkmark {
            border-radius: 0;

            //Style outer quad
            height: 22px;
            width: 22px;
            background-color: $white;
            border: 1px solid $primary-color;

            &:after {
                border-radius: 0;

                //Style inner quad
                width: 10px;
                height: 10px;
                background-color: $primary-color;
            }
        }
    }

    // 4. Radio
    // ---------

    .container-radio {
        border-radius: 100%;

        /* Style the checkmark/indicator */
        .checkmark {
            border-radius: 100%;

            //Style outer circle
            height: 22px;
            width: 22px;
            background-color: $white;
            border: 1px solid $primary-color;

            &:after {
                border-radius: 100%;

                //Style inner circle
                width: 10px;
                height: 10px;
                background-color: $primary-color;
            }
        }
    }
}