//  Cookie
//  -----------------------------

.cc-window {
  &.cc-floating {
    flex-direction: column !important;
    min-width: 100% !important;
    max-width: 100% !important;
    bottom: 0 !important;
    @include breakpoint(medium) {
      flex-direction: row !important;
    }
  }
  .cc-message {
    max-width: $global-width !important;
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 1em !important;
    @include lato-reg;
    @include breakpoint(medium) {
      margin-bottom: 0 !important;
    }
    .cc-link {
      display: none;
      padding: 0;
      opacity: 1;
      color: $primary-color;
      &:hover {
        color: $secondary-color;
      }
      &:visited {
        color: $secondary-color;
      }
    }
  }
  .cc-compliance {
    max-width: $global-width !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 10px;
    .cc-btn {
      @include lato-heavy;
      @include trans-color;
      @include trans-bg-color;
      min-width: 140px;
      max-width: 140px;
      border: 1px solid $primary-color;
      text-transform: uppercase;
      &:hover {
        background-color: $primary-color;
        color: $white;
        text-decoration: none;
      }
    }
  }
}
