//  Image with text 3 columns
//  -----------------------------

.img-txt-3-columns {
    .h1 {
        margin-bottom: 2.5rem;
        @include breakpoint(medium) {
            margin-bottom: 5.5rem;
        }
    }
    .img-txt-container {
        img {
            margin-bottom: rem-calc(18);
            width: 100%;
        }
        h4 {
            font-size: rem-calc(15);
        }
    }
    .columns{
        &.end{
            clear: left;   
        } 
    }
}