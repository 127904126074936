body {
  #ccc {
    #ccc-overlay {
      background: rgba(0, 0, 0, 0.6);
      z-index: 9998 !important;
    }
    #ccc-module {
      z-index: 9999 !important;
    }
    position: static !important;
    padding: 0;
    #ccc-content {
      max-width: 888px;
      #ccc-close {
        display: none;
      }
      button {
        border-radius: 0 !important;
        margin: 0;

        border: none;
        font-size: rem-calc(22);
        overflow: visible;
      }
      .button-wrapper {
        text-align: right;
        &.show-details {
          position: relative;
          a {
            position: absolute;
            top: 40px;
            right: 0;
          }
        }
        &.accept-all {
          button {
            padding: 5px 25px !important;
            background: $primary-color !important;
            color: $white !important;
            margin-bottom: 10px;
            @media only screen and (min-width: 768px) {
              margin-bottom: 0;
            }
          }
        }

        &.accept-selected,
        &.button-wrapper {
          button {
            margin-bottom: 10px;
            padding: 2px 25px !important;
            border: 3px solid $primary-color !important;
            color: $primary-color;
            &::after {
              border-left: 3px solid $primary-color;
              right: -11px;
              bottom: -11px;
            }
          }
          @media only screen and (min-width: 768px) {
            position: relative;
            button {
              position: absolute;
              right: 200px;
              margin-bottom: 0;
            }
          }
        }
      }
      #cc-panel {
        padding: 15px 5px;
        position: static;
        #ccc-button-holder {
          padding-bottom: 64px;
          text-align: right;

          button {
            padding: 5px 25px !important;
            background: $primary-color !important;
            color: $white !important;
            @media screen and (max-width: 386px) {
              width: 100%;
            }
          }
        }
        #ccc-intro {
          margin-bottom: 0;
          a {
            &:not(.textlink) {
              text-decoration: underline;
              font-weight: normal;
            }
            &.textlink {
              display: inline-block;
              padding: 25px 0px;
              font-size: rem-calc(14);
              font-weight: bold;
              line-height: rem-calc(14);
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
              span {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
              }
            }
          }
          a.textlink {
            &::after {
              display: none;
            }
            &::before {
              margin-right: 5px;
              background-position: 0 -30px;
            }
          }
        }

        h2 {
          color: $primary-color !important;
        }
        #ccc-optional-categories {
          padding: 0;
          + hr {
            display: none;
          }
        }
      }
      .checkbox-toggle {
        background: none;
        border: none;
        .checkbox-toggle-label {
          width: 20px;
          height: 20px;
        }
        .checkbox-toggle-input {
          position: static !important;
        }
        .icheckbox {
          @include image-png("icons/checkbox-pink", 20px, 20px);
          &.disabled {
            background-position: 0 -60px;
          }
          &.disabled.checked {
            background-position: 0 -60px;
          }
          &.hover {
            background-position: 0 -20px;
            z-index: 99;
          }
          &.checked {
            background-position: 0 -40px;
          }
        }
      }
    }
    &[popup] {
      #ccc-content {
        border-radius: 0;
        max-width: 888px;
        max-height: 90vh;
        height: auto;
        .ccc-info {
          display: none;
        }
      }
    }
  }
}
.tx-wisag-civic-open-settings {
  cursor: pointer;
}

// Hide settings initially
#ccc-button-holder + hr,
#ccc-necessary-title,
#ccc-necessary-description,
#ccc-necessary-description + hr,
#ccc-optional-categories {
  display: none;
}

// Unhide settings after the markup has been altered, but hide the container initially
#ccc-all-categories-container {
  display: none;

  #ccc-button-holder + hr,
  #ccc-necessary-title,
  #ccc-necessary-description,
  #ccc-necessary-description + hr,
  #ccc-optional-categories,
  hr {
    display: block;
  }
}

#ccc .necessary-cookie {
  position: relative;
}

#tx-wisag-civic-list {
  font-size: 0.75rem;

  th {
    text-align: left;
  }
}

#ccc-all-categories-container {
  button {
    &.tx-wisag-civic-accept-selected {
      right: 0 !important;
      position: relative !important;
    }
  }
}
