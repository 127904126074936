//  Site Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Paths
//   2. Fonts
//   3. Page Content
//   4. Footer

// 1. Paths
// ---------

$image-path: "../img";
$retina-image-path: "../img/2x";

// 2. Fonts
// ---------

@font-face {
  font-family: "font-example";
  src: url("../fonts/font-example.eot");
  src: url("../fonts/font-example.eot?#iefix") format("embedded-opentype"),
    url("../fonts/font-example.woff") format("woff"),
    url("../fonts/font-example.ttf") format("truetype"),
    url("../fonts/font-example.svg#font-family-reg") format("svg");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "museo-900";
  src: url("../fonts/museo900-regular.otf");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "museo-700";
  src: url("../fonts/Museo700-Regular.otf");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "museo-500";
  src: url("../fonts/museo500-regular.otf");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "museo-300";
  src: url("../fonts/museo300-regular.otf");
  src: url("../fonts/museo300-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "lato-regular";
  src: url("../fonts/lato-regular-webfont.eot");
  src: url("../fonts/lato-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/lato-regular-webfont.woff") format("woff"),
    url("../fonts/lato-regular-webfont.ttf") format("truetype"),
    url("../fonts/lato-regular-webfont.svg#latoregular") format("svg");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "lato-heavy";
  src: url("../fonts/lato-heavy-webfont.eot");
  src: url("../fonts/lato-heavy-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/lato-heavy-webfont.woff") format("woff"),
    url("../fonts/lato-heavy-webfont.ttf") format("truetype"),
    url("../fonts/lato-heavy-webfont.svg#latoheavy") format("svg");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

// 3. Page Content
// ---------

$pagecontent-padding-top: rem-calc(30);
$pagecontent-padding-bottom: rem-calc(30);
$pagecontent-padding-side: rem-calc(10);

// 4. Footer
// ---------

$footer-min-height: rem-calc(50);
