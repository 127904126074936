//  Global Styles
//  -----------------------------
//
//  Table of Contents:
//
//   1. Headers & Copy
//   2. Lists
//   3. Links and Buttons
//   4. Background-Colors & Color-Themes
//   5. JS Utilities
//   6. FadeIn

// 1. Headers
// ---------

h1,
.h1 {
  @include museo-900;
  &.statement {
    &::before {
      content: "";
      display: block;
      width: 60px;
      height: 5px;
      background-color: $white;
      margin-bottom: 9px;
      @include breakpoint(medium) {
        margin-bottom: 15px;
      }
    }
  }
  @include breakpoint(medium) {
    line-height: rem-calc(50);
  }
}

h2,
.h2 {
  @include lato-heavy;
  line-height: rem-calc(42);
}

h3,
.h3 {
  @include museo-900;
  line-height: rem-calc(28);
}

h4,
.h4 {
  @include museo-900;
  line-height: rem-calc(23);
  text-transform: uppercase;
}

h5,
.h5 {
  @include museo-500;
  line-height: rem-calc(20);
  text-transform: uppercase;
}

p {
  @include lato-reg;
  font-size: rem-calc(15);
  line-height: rem-calc(25);
}

// 1. Lists
// ---------

ul {
  margin-left: 25px;
  li {
    @include lato-reg;
    font-size: rem-calc(15);
    line-height: rem-calc(25);
    &:before {
      content: "//";
      position: absolute;
      display: block;
      font-size: rem-calc(15);
      line-height: rem-calc(25);
      color: $secondary-color;
      left: -25px;
    }
  }
  &.big {
    li {
      position: relative;
      list-style: none;
      font-size: rem-calc(20);
      line-height: rem-calc(30);
      &:before {
        content: "//";
        font-size: rem-calc(20);
        line-height: rem-calc(30);
      }
    }
  }
}

ol {
  li {
    @include lato-reg;
    font-size: rem-calc(15);
    line-height: rem-calc(25);
  }
}

// 3. Links and Buttons
// ---------

a {
  &:hover {
    text-decoration: underline;
  }
}

.textlink {
  @include lato-heavy;
  font-size: rem-calc(13);
  line-height: rem-calc(20);
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  color: $primary-color;
  @include trans-color;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: $primary-color;
    @include trans-bg-color;
  }
  &:hover {
    text-decoration: none;
    color: $secondary-color;
    &:after {
      background-color: $secondary-color;
    }
  }
  &.secondary {
    &:hover {
      text-decoration: none;
      color: $white;
      &:after {
        background-color: $white;
      }
    }
  }
  &.icon-phone {
    position: relative;
    font-size: rem-calc(16);
    text-align: left;
    padding-left: 22px;
    -ms-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease;
    &::before {
      content: "";
      @include image-svg("icons/icn-phone-dark-gray", 18px, 19px);
      display: inline-block;
      position: absolute;
      vertical-align: middle;
      left: 0;
    }
    &:after {
      display: none;
    }
    &:hover {
      &:after {
        content: "";
        position: absolute;
        right: 0;
        left: auto;
        bottom: 0;
        width: calc(100% - 28px);
        height: 2px;
        background-color: $white;
        @include trans-bg-color;
      }
      &::before {
        content: "";
        background-image: url(../img/icons/icn-phone-white.svg) !important;
        -ms-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
      }
    }
    &.secondary {
      &::before {
        content: "";
        @include image-svg("icons/icn-phone", 18px, 19px);
        display: inline-block;
        position: absolute;
        vertical-align: middle;
        left: 0;
      }
    }
  }
}

button,
.button {
  background-color: transparent;
  border: 1px solid $primary-color;
  color: $primary-color;
  padding: rem-calc(15 25);
  text-transform: uppercase;
  @include lato-heavy;
  font-size: rem-calc(13);
  line-height: rem-calc(15);
  &:hover {
    background-color: $primary-color;
  }
  &.white {
    border: 1px solid $white;
    color: $white;
    &:hover {
      background-color: $white;
      color: $primary-color !important;
    }

    &.fill {
      background-color: $white;
      color: $primary-color !important;

      &:hover {
        background-color: $primary-color;
        color: $white !important;
      }
    }
  }
}

//Hide all focus states
*:focus {
  outline: none !important;
}

// 4. Background-Colors & Color-Themes
// ---------

.bg-primary {
  background-color: $primary-color;
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  a,
  span,
  div,
  .button {
    color: $white;
    &.secondary {
      color: $secondary-color;
    }
    &.white {
      color: $white;
    }
    &:after {
      background-color: $white;
    }
  }
  ul {
    li {
      color: $secondary-color;
    }
  }
  &.gradient {
    @include gradient-primary;
  }
}
.bg-secondary {
  background-color: $secondary-color;
  h1,
  h2,
  h3,
  h4,
  p,
  a,
  span,
  div {
    color: $white;
    &.primary {
      color: $primary-color;
    }
    &.white {
      color: $white;
    }
    &:after {
      background-color: $white;
    }
  }
  ul {
    li {
      color: $primary-color;
      &:before {
        color: $primary-color;
      }
    }
  }
  &.gradient {
    @include gradient-secondary;
  }
}
.bg-light-gray {
  background-color: $light-gray;
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  a,
  span {
    color: $dark-gray;
    &.primary {
      color: $primary-color;
    }
    &.secondary {
      color: $secondary-color;
    }
    &.button {
      color: $primary-color;
    }
    &.icon-phone {
      &:hover {
        &::before {
          content: "";
          background-image: url(../img/icons/icn-phone-secondary.svg) !important;
        }
      }
    }
  }
}
.bg-medium-gray {
  background-color: $medium-gray;
  h1,
  h2,
  h3,
  h4,
  p,
  span {
    color: $white;
    &.primary {
      color: $primary-color;
    }
    &.secondary {
      color: $secondary-color;
    }
  }
}
.bg-dark-gray {
  background-color: $dark-gray;
  h1,
  h2,
  h3,
  p,
  a,
  span {
    color: $white;
    &.primary {
      color: $primary-color;
    }
    &.secondary {
      color: $secondary-color;
    }
  }
}
.bg-white {
  background-color: $white;
}

// 5. JS Utilities
// ---------

.hidden-content {
  display: none;
}

.toggle-menu {
  position: relative;

  .action-close {
    display: none;
  }

  &.active {
    .action-open {
      display: none;
    }
    .action-close {
      display: inline-block;
    }
  }
}

// 6. FadeIn
// ---------

body.stop-transitions * {
  -ms-transition: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

.with-fade-in {
  opacity: 1;
  -ms-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  &.delay-1 {
    -ms-transition-delay: opacity 1s ease;
    -moz-transition-delay: opacity 1s ease;
    -webkit-transition-delay: opacity 1s ease;
    transition-delay: 0.25s;
  }
  &.delay-2 {
    -ms-transition-delay: opacity 1s ease;
    -moz-transition-delay: opacity 1s ease;
    -webkit-transition-delay: opacity 1s ease;
    transition-delay: 0.5s;
  }
  &.delay-3 {
    -ms-transition-delay: opacity 1s ease;
    -moz-transition-delay: opacity 1s ease;
    -webkit-transition-delay: opacity 1s ease;
    transition-delay: 0.75s;
  }
  &.delay-4 {
    -ms-transition-delay: opacity 1s ease;
    -moz-transition-delay: opacity 1s ease;
    -webkit-transition-delay: opacity 1s ease;
    transition-delay: 1s;
  }
  &.delay-5 {
    -ms-transition-delay: opacity 1.25s ease;
    -moz-transition-delay: opacity 1.25s ease;
    -webkit-transition-delay: opacity 1.25s ease;
    transition-delay: 1.25s;
  }
  &.delay-6 {
    -ms-transition-delay: opacity 1.5s ease;
    -moz-transition-delay: opacity 1.5s ease;
    -webkit-transition-delay: opacity 1.5s ease;
    transition-delay: 1.5s;
  }
  &.fade-in {
    opacity: 0;
  }
}

.nowrap {
  white-space: nowrap;
}

.with-fade-up {
  opacity: 1;
  -ms-transition: opacity 1s ease, transform 1s ease;
  -moz-transition: opacity 1s ease, transform 1s ease;
  -webkit-transition: opacity 1s ease, transform 1s ease;
  transition: opacity 1s ease, transform 1s ease;
  -ms-transform: translate(0%, 0%);
  -moz-transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  &.fade-in {
    opacity: 0;
    -ms-transform: translate(0%, 30%);
    -moz-transform: translate(0%, 30%);
    -webkit-transform: translate(0%, 30%);
    transform: translate(0%, 30%);
  }
}

.with-fade-to-right {
  opacity: 1;
  -ms-transition: opacity 1s ease, transform 1s ease;
  -moz-transition: opacity 1s ease, transform 1s ease;
  -webkit-transition: opacity 1s ease, transform 1s ease;
  transition: opacity 1s ease, transform 1s ease;
  -ms-transform: translate(0%, 30%);
  -moz-transform: translate(0%, 30%);
  -webkit-transform: translate(0%, 30%);
  transform: translate(0%, 0%);
  &.fade-in {
    opacity: 0;
    -ms-transform: translate(-10%, 0%);
    -moz-transform: translate(-10%, 0%);
    -webkit-transform: translate(-10%, 0%);
    transform: translate(-10%, 0%);
  }
}

.grecaptcha-badge {
  z-index: 9999;
}
