//
// Interactive map slider
//
.interactive-map {
  position: relative;
  overflow: hidden;

  .page-content {
    @include breakpoint(small only) {
      padding-bottom: 40px;
      padding-right: 0px;
      padding-left: 0px;
    }
    @include breakpoint(medium) {
      padding-bottom: 80px;
      padding-left: 0px;
      padding-right: 0px;
    }
    @include breakpoint(large) {
      padding-bottom: 120px;
      padding-right: 0px;
    }

    @include breakpoint(xxlarge) {
      padding-right: 80px;
    }

    .section-headline {
      @include breakpoint(small only) {
        padding-left: 10px;
      }
    }

    .map-slider-container {
      display: block;
      position: relative;
      width: 100%;
      max-width: 87.875rem;
      margin: 0 auto;
      @include breakpoint(medium) {
        display: flex !important;
      }
      .map-wrapper {
        width: 100%;
        position: relative;
        margin-top: 4rem;
        @include breakpoint(small only) {
          padding-left: 10px;
        }
        @include breakpoint(medium) {
          width: 35%;
          margin-top: 5rem;
        }
        .map-text-slider {
          .slide {
            .description {
              h2 {
                font-family: museo-500;
              }
            }
          }
        }

        button {
          &:hover {
            @include breakpoint(medium down) {
              background-color: unset !important;
            }
          }
        }

        .slick-arrow {
          cursor: pointer;
          border: none;
          text-align: center;
          position: absolute;
          top: -40px;
          bottom: auto;
          font-size: 0;
          transform: none;
          padding: 0;
          line-height: 0;
          margin: 0;
          z-index: 2;
          &:hover {
            @include breakpoint(medium) {
              background-color: transparent;
              &.slick-prev,
              &.slick-next {
                &:before {
                  background-image: url("../img/icons/icn-arrow-slider-primary.svg");
                  background-repeat: no-repeat;
                }
              }
            }
          }
          &:focus {
            fill: $primary-color;
            outline: 0;
          }
          &.slick-prev {
            left: calc(0% + 9px);
            @include breakpoint(medium) {
              left: calc(0% + 22px);
            }
            &:before {
              content: "";
              background-image: url("../img/icons/icn-arrow-slider.svg");
              background-repeat: no-repeat;
              height: 28px;
              width: 20px;
              fill: $dark-gray;
              display: block;
              transform: scale(-1);
              -webkit-transform: scale(-1);
              -ms-transform: scale(-1);
              &:focus {
                border: none;
              }
            }
          }
          &.slick-next {
            right: auto;
            left: 4rem;
            &:before {
              content: "";
              background-image: url("../img/icons/icn-arrow-slider.svg");
              background-repeat: no-repeat;
              height: 28px;
              width: 20px;
              fill: $dark-gray;
              display: block;
            }
          }
        }
      }

      .map-slider {
        margin-top: -2rem;
        width: 100% !important;
        @include image-svg("../img/icons/icn-grafik-map", 774px, 499px);
        z-index: 1;
        margin-left: 15px;

        @media screen and (max-width: 400px) {
          background-size: 480px 310px;
          height: 310px;
        }

        @media screen and (min-width: 400px) {
          background-size: 664px 429px;
          height: 429px;
        }

        @include breakpoint(medium) {
          margin-top: 0;
          width: 85% !important;
          min-height: 669px;
          left: -20%;
          background-size: 1037px 669px;
          margin-left: 0;
        }

        @media screen and (min-width: 640px) and (max-width: 730px) {
          width: 100% !important;
          left: -32%;
        }

        .slick-list {
          position: relative;
          min-height: 100%;
          .slide {
            .img-wrapper {
              position: relative;
              width: 100%;
              .img {
                margin: 0 auto;
                position: relative;
                display: block;
                @media screen and (max-width: 400px) {
                  left: -90px;
                }
                .map-dot {
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background: $medium-gray;
                  @media screen and (min-width: 400px) {
                    width: 7px;
                    height: 7px;
                  }
                  @include breakpoint(medium) {
                    width: 12px;
                    height: 12px;
                  }
                  .map-line {
                    position: absolute;
                    width: 330px;
                    height: 2px;
                    background-color: $primary-color;
                    z-index: 3;
                    @include breakpoint(small only) {
                      display: none;
                    }
                    &.pos1 {
                      //BCN Barcelona
                      top: 0.3rem;
                      right: 0;
                      width: 335px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 359px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos2 {
                      // BGO Bergen
                      top: 0.3rem;
                      right: 0;
                      width: 450px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 49px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos3 {
                      // BWE Braunschweig
                      top: 0.3rem;
                      right: 0;
                      width: 480px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 169px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos4 {
                      // EMA East Midlands
                      top: 0.3rem;
                      right: 0;
                      width: 350px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 169px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos5 {
                      // FMM Memmingen
                      top: 0.3rem;
                      right: 0;
                      width: 474px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 269px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos6 {
                      //FMO Münster-Osnabrück
                      top: 0.295rem;
                      right: 0;
                      width: 430px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 169px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos7 {
                      //GOT Göteborg
                      width: 500px;
                      top: 0.295rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 109px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos8 {
                      //  HEL Helsinki
                      width: 606px;
                      top: 0.3rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 69px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos9 {
                      // MAD Madrid
                      top: 0.295rem;
                      right: 0;
                      width: 239px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 359px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos10 {
                      // MXP Mailand-Malpensa
                      top: 0.3rem;
                      right: 0;
                      width: 444px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 289px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos11 {
                      // PDL Ponta Delgada
                      top: 0.295rem;
                      right: 0;
                      width: 7px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 359px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos12 {
                      // TER Lajes Field
                      top: 0.295rem;
                      right: 0;
                      width: 7px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 359px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos13 {
                      // TOS Tromsø
                      top: 0.3rem;
                      right: 0;
                      width: 440px;
                      &:before {
                        display: none;
                      }
                    }
                    &.pos14 {
                      // VIT Vitoria
                      top: 0.295rem;
                      right: 0;
                      width: 278px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 299px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos15 {
                      // Köln
                      top: 0.3rem;
                      right: 0;
                      width: 430px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 229px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos16 {
                      // Düsseldorf
                      top: 0.3rem;
                      right: 0;
                      width: 415px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 217px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos17 {
                      // Hamburg
                      top: 0.3rem;
                      right: 0;
                      width: 452px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 183px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos18 {
                      // Rostock
                      width: 488px;
                      top: 0.4rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 171px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos19 {
                      // Berlin
                      width: 510px;
                      top: 0.3rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 206px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos20 {
                      // München
                      width: 484px;
                      top: 0.35rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 286px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos21 {
                      // Stuttgart
                      top: 0.4rem;
                      right: 0;
                      width: 450px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 275px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos22 {
                      // Frankfurt (Main)
                      top: 0.3rem;
                      right: 0;
                      width: 440px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 252px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos23 {
                      // Leipzig
                      width: 500px;
                      top: 0.4rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 229px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos24 {
                      // Vienna
                      width: 526px;
                      top: 0.35rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 286px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos25 {
                      // Zürich
                      top: 0.4rem;
                      right: 0;
                      width: 444px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 299px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos26 {
                      // Luxemburg
                      top: 0.4rem;
                      right: 0;
                      width: 408px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 263px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos27 {
                      // Brüssel
                      top: 0.3rem;
                      right: 0;
                      width: 395px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 229px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos28 {
                      // Copenhagen
                      width: 490px;
                      top: 0.4rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 137px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos29 {
                      // Oslo
                      width: 490px;
                      top: 0.4rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 137px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos30 {
                      // Lisbon
                      top:0.295rem;
                      right:0;
                      width:143px;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height:389px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos31 {
                      // Porto
                      width: 160px;
                      top: 0.3rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 389px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                    &.pos32 {
                      // Budapest
                      width: 560px;
                      top: 0.3rem;
                      right: 0;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 350px;
                        bottom: 0;
                        background-color: $primary-color;
                      }
                    }
                  }
                  &.pos1 {
                    // BCN Barcelona
                    top: calc(384px - 152px);
                    left: calc(165px + 76px);
                    @media screen and (min-width: 400px) {
                      top: calc(384px - 56px);
                      left: calc(165px + 44px);
                    }
                    @include breakpoint(medium) {
                      top: 522px;
                      left: calc(182px + 143px);
                    }
                  }
                  &.pos2 {
                    // BGO Bergen
                    top: calc(144px - 61px);
                    left: calc(253.1px + 43px);
                    @media screen and (min-width: 400px) {
                      top: calc(144px - 22px);
                      left: calc(253.1px + 32px);
                    }
                    @include breakpoint(medium) {
                      top: 199.55px;
                      left: calc(301.1px + 143px);
                    }
                  }
                  &.pos3 {
                    // BWE Braunschweig
                    top: calc(273px - 110px);
                    left: calc(271.2px + 36px);
                    @media screen and (min-width: 400px) {
                      top: calc(273px - 41px);
                      left: calc(271.2px + 29px);
                    }
                    @include breakpoint(medium) {
                      top: 372.55px;
                      left: calc(325.2px + 143px);
                    }
                  }
                  &.pos4 {
                    // EMA East Midlands
                    top: calc(239px - 97px);
                    left: calc(174px + 73px);
                    @media screen and (min-width: 400px) {
                      top: calc(239px - 36px);
                      left: calc(174px + 42px);
                    }
                    @include breakpoint(medium) {
                      top: 326.55px;
                      left: calc(195px + 143px);
                    }
                  }
                  &.pos5 {
                    // FMM Memmingen
                    top: calc(341px - 136px);
                    left: calc(271.5px + 35px);
                    @media screen and (min-width: 400px) {
                      top: calc(341px - 50px);
                      left: calc(271.5px + 35px);
                    }
                    @include breakpoint(medium) {
                      top: 464.5px;
                      left: calc(325px + 143px);
                    }
                  }
                  &.pos6 {
                    // FMO Münster-Osnabrück
                    top: calc(263.5px - 106px);
                    left: calc(236px + 49px);
                    @media screen and (min-width: 400px) {
                      top: calc(263.5px - 39px);
                      left: calc(236px + 34px);
                    }
                    @include breakpoint(medium) {
                      top: 361.5px;
                      left: calc(278px + 143px);
                    }
                  }
                  &.pos7 {
                    // GOT Göteborg
                    top: calc(196px - 81px);
                    left: calc(288.5px + 29px);
                    @media screen and (min-width: 400px) {
                      top: calc(196px - 30px);
                      left: calc(288.5px + 26px);
                    }
                    @include breakpoint(medium) {
                      top: 269px;
                      left: calc(348px + 143px);
                    }
                  }
                  &.pos8 {
                    // HEL Helsinki
                    top: calc(162px - 68px);
                    left: 366.5px;
                    @media screen and (min-width: 400px) {
                      top: calc(162px - 25px);
                      left: calc(368.5px + 14px);
                    }
                    @include breakpoint(medium) {
                      top: 222px;
                      left: calc(456px + 143px);
                    }
                  }
                  &.pos9 {
                    // MAD Madrid
                    top: calc(393px - 161px);
                    left: calc(94px + 103px);
                    @media screen and (min-width: 400px) {
                      top: calc(393px - 57px);
                      left: calc(94px + 47px);
                    }
                    @include breakpoint(medium) {
                      top: 533px;
                      left: calc(87px + 143px);
                    }
                  }
                  &.pos10 {
                    // MXP Mailand-Malpensa
                    top: calc(358px - 142px);
                    left: calc(244.6px + 45px);
                    @media screen and (min-width: 400px) {
                      top: calc(358px - 52px);
                      left: calc(244.6px + 32px);
                    }
                    @include breakpoint(medium) {
                      top: 487px;
                      left: calc(289.45px + 143px);
                    }
                  }
                  &.pos11 {
                    // PDL Ponta Delgada
                    top: 248px;
                    left: 99px;
                    @media screen and (min-width: 400px) {
                      top: 350px;
                      left: 12px;
                    }
                    @include breakpoint(medium) {
                      top: 555px;
                      left: 18px;
                    }
                  }
                  &.pos12 {
                    // TER Lajes Field
                    top: 243px;
                    left: 91px;
                    @media screen and (min-width: 400px) {
                      top: 343px;
                      left: 1px;
                    }
                    @include breakpoint(medium) {
                      top: 545px;
                      left: 1px;
                    }
                  }
                  &.pos13 {
                    // TOS Tromsø
                    top: 14px;
                    left: 556px;
                    @media screen and (min-width: 400px) {
                      top: 26px;
                      left: 368px;
                    }
                    @include breakpoint(medium) {
                      top: 49px;
                      left: 574px;
                    }
                  }
                  &.pos14 {
                    // VIT Vitoria
                    top: 216px;
                    left: 214px;
                    @media screen and (min-width: 400px) {
                      top: 306px;
                      left: 171px;
                    }
                    @include breakpoint(medium) {
                      top: 488px;
                      left: 267px;
                    }
                  }
                  &.pos15 {
                    // Köln
                    top: calc(290px - 116px);
                    left: calc(236.1px + 49px);
                    @media screen and (min-width: 400px) {
                      top: calc(290px - 43px);
                      left: calc(236.1px + 33px);
                    }
                    @include breakpoint(medium) {
                      top: 395px;
                      left: calc(278.1px + 143px);
                    }
                  }
                  &.pos16 {
                    // Düsseldorf
                    top: calc(282px - 113px);
                    left: calc(227.1px + 52px);
                    @media screen and (min-width: 400px) {
                      top: calc(282px - 43px);
                      left: calc(227.1px + 35px);
                    }
                    @include breakpoint(medium) {
                      top: 383px;
                      left: calc(266px + 143px);
                    }
                  }
                  &.pos17 {
                    // Hamburg
                    top: calc(256px - 103px);
                    left: calc(254px + 42px);
                    @media screen and (min-width: 400px) {
                      top: calc(256px - 39px);
                      left: calc(254px + 31px);
                    }
                    @include breakpoint(medium) {
                      top: 349px;
                      left: calc(301px + 143px);
                    }
                  }
                  &.pos18 {
                    // Rostock
                    top: calc(247px - 100px);
                    left: calc(280px + 33px);
                    @media screen and (min-width: 400px) {
                      top: calc(247px - 37px);
                      left: calc(280px + 28px);
                    }
                    @include breakpoint(medium) {
                      top: 337px;
                      left: calc(337px + 143px);
                    }
                  }
                  &.pos19 {
                    // Berlin
                    top: calc(273px - 110px);
                    left: calc(298px + 25px);
                    @media screen and (min-width: 400px) {
                      top: calc(273px - 40px);
                      left: calc(298px + 25px);
                    }
                    @include breakpoint(medium) {
                      top: 372px;
                      left: calc(360.8px + 143px);
                    }
                  }
                  &.pos20 {
                    // München
                    top: calc(333px - 133px);
                    left: calc(280px + 32px);
                    @media screen and (min-width: 400px) {
                      top: calc(333px - 49px);
                      left: calc(280px + 35px);
                    }
                    @include breakpoint(medium) {
                      top: 452px;
                      left: calc(337px + 143px);
                    }
                  }
                  &.pos21 {
                    // Stuttgart
                    top: calc(324px - 129px);
                    left: calc(254.1px + 42px);
                    @media screen and (min-width: 400px) {
                      top: calc(324px - 48px);
                      left: calc(254.1px + 31px);
                    }
                    @include breakpoint(medium) {
                      top: 441px;
                      left: calc(301.1px + 143px);
                    }
                  }
                  &.pos22 {
                    // Frankfurt (Main)
                    top: calc(308px - 124px);
                    left: calc(244.6px + 46px);
                    @media screen and (min-width: 400px) {
                      top: calc(308px - 46px);
                      left: calc(244.6px + 32px);
                    }
                    @include breakpoint(medium) {
                      top: 418px;
                      left: calc(289.6px + 143px);
                    }
                  }
                  &.pos23 {
                    // Leipzig
                    top: calc(290px - 116px);
                    left: calc(288.6px + 29px);
                    @media screen and (min-width: 400px) {
                      top: calc(290px - 43px);
                      left: calc(288.6px + 26px);
                    }
                    @include breakpoint(medium) {
                      top: 395px;
                      left: calc(349.6px + 143px);
                    }
                  }
                  &.pos24 {
                    // Vienna
                    top: calc(333px - 133px);
                    left: calc(323.6px + 4px);
                    @media screen and (min-width: 400px) {
                      top: calc(333px - 49px);
                      left: calc(323.6px + 14px);
                    }
                    @include breakpoint(medium) {
                      top: 452px;
                      left: calc(396.6px + 119px);
                    }
                  }
                  &.pos25 {
                    // Zürich
                    top: calc(341px - 135px);
                    left: calc(244.6px + 45px);
                    @media screen and (min-width: 400px) {
                      top: calc(341px - 50px);
                      left: calc(244.6px + 32px);
                    }
                    @include breakpoint(medium) {
                      top: 464px;
                      left: calc(289.45px + 143px);
                    }
                  }
                  &.pos26 {
                    // Luxemburg
                    top: calc(315px - 125px);
                    left: calc(217.6px + 56px);
                    @media screen and (min-width: 400px) {
                      top: calc(315px - 46px);
                      left: calc(217.6px + 37px);
                    }
                    @include breakpoint(medium) {
                      top: 429px;
                      left: calc(254.6px + 143px);
                    }
                  }
                  &.pos27 {
                    // Brüssel
                    top: calc(290px - 116px);
                    left: calc(208.6px + 60px);
                    @media screen and (min-width: 400px) {
                      top: calc(290px - 43px);
                      left: calc(208.6px + 38px);
                    }
                    @include breakpoint(medium) {
                      top: 395px;
                      left: calc(242.6px + 143px);
                    }
                  }
                  &.pos28 {
                    // Copenhagen
                    top: calc(221px - 90px);
                    left: calc(280px + 32px);
                    @media screen and (min-width: 400px) {
                      top: calc(221px - 33px);
                      left: calc(280px + 27px);
                    }
                    @include breakpoint(medium) {
                      top: 303px;
                      left: calc(337px + 143px);
                    }
                  }
                  &.pos29 {
                    // Oslo
                    // top: calc(221px - 90px);
                    // left: calc(280px + 32px);

                    top: 210px;
                    left: 492px;
                    // @media screen and (min-width: 400px) {
                    //   top: calc(221px - 33px);
                    //   left: calc(280px + 27px);
                    // }
                    // @include breakpoint(medium) {
                    //   top: 303px;
                    //   left: calc(337px + 143px);
                    // }
                  }
                  &.pos30 {
                    // Lisbon
                    top: calc(393px - 150px);
                    left: calc(94px + 65px);
                    @media screen and (min-width: 400px) {
                      top: calc(393px - 50px);
                      left: calc(94px + 1px);
                    }
                    @include breakpoint(medium) {
                      top: 544px;
                      left: calc(87px + 61px);
                    }
                  }
                  &.pos31 {
                    // Porto
                    top: calc(221px + 17px);
                    left: calc(280px - 116px);
                    @media screen and (min-width: 400px) {
                      top: calc(221px + 114px);
                      left: calc(280px - 177px);
                    }
                    @include breakpoint(medium) {
                      top: 533px;
                      left: calc(337px - 178px);
                    }
                  } 
                  &.pos32 {
                    // Budapest
                    top: calc(221px - 15px);
                    left: calc(280px + 65px);
                    @media screen and (min-width: 400px) {
                      top: calc(221px + 70px);
                      left: calc(280px + 80px);
                    }
                    @include breakpoint(medium) {
                      top: 464px;
                      left: calc(337px + 214px);
                    }
                  }
                  button {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: none;
                    padding: 0;
                    margin: 0;
                    border-radius: 100%;
                    background-color: transparent;
                    z-index: 33;
                    cursor: pointer;
                    transition: all 0.25s ease-in-out;
                    &:hover {
                      background: $primary-color;
                    }
                  }
                }
                &.step1 {
                  .count1 {
                    background: $primary-color;
                  }
                }
                &.step2 {
                  .count2 {
                    background: $primary-color;
                  }
                }
                &.step3 {
                  .count3 {
                    background: $primary-color;
                  }
                }
                &.step4 {
                  .count4 {
                    background: $primary-color;
                  }
                }
                &.step5 {
                  .count5 {
                    background: $primary-color;
                  }
                }
                &.step6 {
                  .count6 {
                    background: $primary-color;
                  }
                }
                &.step7 {
                  .count7 {
                    background: $primary-color;
                  }
                }
                &.step8 {
                  .count8 {
                    background: $primary-color;
                  }
                }
                &.step9 {
                  .count9 {
                    background: $primary-color;
                  }
                }
                &.step10 {
                  .count10 {
                    background: $primary-color;
                  }
                }
                &.step11 {
                  .count11 {
                    background: $primary-color;
                  }
                }
                &.step12 {
                  .count12 {
                    background: $primary-color;
                  }
                }
                &.step13 {
                  .count13 {
                    background: $primary-color;
                  }
                }
                &.step14 {
                  .count14 {
                    background: $primary-color;
                  }
                }
                &.step15 {
                  .count15 {
                    background: $primary-color;
                  }
                }
                &.step16 {
                  .count16 {
                    background: $primary-color;
                  }
                }
                &.step17 {
                  .count17 {
                    background: $primary-color;
                  }
                }
                &.step18 {
                  .count18 {
                    background: $primary-color;
                  }
                }
                &.step19 {
                  .count19 {
                    background: $primary-color;
                  }
                }
                &.step20 {
                  .count20 {
                    background: $primary-color;
                  }
                }
                &.step21 {
                  .count21 {
                    background: $primary-color;
                  }
                }
                &.step22 {
                  .count22 {
                    background: $primary-color;
                  }
                }
                &.step23 {
                  .count23 {
                    background: $primary-color;
                  }
                }
                &.step24 {
                  .count24 {
                    background: $primary-color;
                  }
                }
                &.step25 {
                  .count25 {
                    background: $primary-color;
                  }
                }
                &.step26 {
                  .count26 {
                    background: $primary-color;
                  }
                }
                &.step27 {
                  .count27 {
                    background: $primary-color;
                  }
                }
                &.step28 {
                  .count28 {
                    background: $primary-color;
                  }
                }
                &.step29 {
                  .count29 {
                    background: $primary-color;
                  }
                }
                &.step30 {
                  .count30 {
                    background: $primary-color;
                  }
                }
                &.step31 {
                  .count31 {
                    background: $primary-color;
                  }
                }
                &.step32 {
                  .count32 {
                    background: $primary-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
