//  Image with text 3 columns
//  -----------------------------

.icn-small-txt-4-columns {
  .h1 {
    margin-bottom: 2.5rem;
    @include breakpoint(medium) {
      margin-bottom: 5.5rem;
    }
  }
  .img-txt-container {
    text-align: center;
    img {
      margin-bottom: rem-calc(18);
      max-height: 61px;
    }
    p {
      font-family: lato-heavy;
      &.primary {
        font-size: rem-calc(15);
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    &.xtreme-small {
      img {
        height: 55px;
        vertical-align: baseline;
      }
    }
  }
  .gse-units-additional {
    @include breakpoint(large) {
      margin-bottom: 1.5rem;
    }
    p {
      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }
  }
}
