//  Icon bar
//  -----------------------------

.icn-bar {
  .row {
    white-space: nowrap;
    .columns {
        height:153px;
    @include breakpoint(medium){
        height:200px;
    }
    @include breakpoint(large){
        height:153px;
    }
      .img-txt-container {
        display: table;
        margin: 1rem auto;
        text-align: center;
        @include breakpoint(large) {
          text-align: left;
          margin: 0 auto;
        }
        img {
          display: block;
          margin: 0 auto rem-calc(15) auto;
          @include breakpoint(medium) {
            margin: 0 auto rem-calc(30) auto;
          }
          @include breakpoint(large) {
            margin: 0 0 rem-calc(15);
          }
        }
        h3 {
          margin-bottom: 0;
          @include breakpoint(medium) {
            margin-bottom: rem-calc(18);
          }
        }
      }
    }
  }
}
