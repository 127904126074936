//  Logo bar
//  -----------------------------

.logo-bar {
  #customer-logos {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    .single-customer {
      margin: 1rem;
      padding: 0 1rem;
      @include breakpoint(xmedium) {
        &:first-child {
          margin-left: 0rem;
          padding: 0rem 1rem 0rem 0rem;
        }
        &:last-child {
          margin-right: 0rem;
          padding: 0rem 0rem 0rem 1rem;
        }
      }
    }
  }
}
