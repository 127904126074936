.job-section {
  text-align: center;

  .button {
    border-color: $white;
  }

  .header-row {
    margin-bottom: 30px;

    @include breakpoint(xmedium) {
      margin-bottom: 60px;
    }

    .h1 {
      span {
        font-family: museo-500;
      }
    }
  }

  .job {
    padding: 0 20px;

    .job-header {
      min-height: 350px;
      padding: 35px 20px 60px;

      .job-location {
        @include museo-900;
        margin-bottom: 30px;
        color: $secondary-color;
        font-size: rem-calc(28);
        line-height: 1.2;
      }

      .job-title {
        line-height: 1.5;
      }
    }

    .job-content {
      margin-top: 30px;

      @include breakpoint(xmedium) {
        margin-top: 80px;
      }

      .button {
        min-width: 150px;
      }
    }
  }

  .job-slider {
    margin: 0 -20px;

    &::after {
      right: 0;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      height: auto;

      > div,
      .job {
        height: 100%;
      }
    }

    .slick-arrow,
    .slick-dots button {
      display: block;
      padding: 0;
      background-color: transparent;
      border: none;
      color: transparent;
      cursor: pointer;
      overflow: hidden;
    }

    .slick-arrow {
      width: 30px;
      height: 30px;
      padding: 0;
      position: absolute;
      background-image: url("../img/icons/icn-arrow-slider-primary.svg");
      background-repeat: no-repeat;

      @include breakpoint(large) {
        width: 40px;
        height: 40px;
      }
    }

    .slick-prev {
      left: -40px;
      transform: scaleX(-1) translateY(-50%);

      @include breakpoint(large) {
        left: -60px;
      }
    }

    .slick-next {
      right: -40px;
      transform: translateY(-50%);

      @include breakpoint(large) {
        right: -60px;
      }
    }

    .slick-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0;
      list-style: none;

      li {
        width: 5px;
        height: 5px;
        margin: 0 2px;
        background-color: $white;
        border-radius: 50%;

        &.slick-active {
          width: 10px;
          height: 10px;
        }

        &::before {
          display: none;
        }

        button {
          height: 100%;
          width: 100%;
        }
      }
    }

    .job {
      display: flex !important;
      flex-direction: column;

      .job-header {
        flex: 1;
      }
    }
  }
}
