.cta-txt-1-columns {
  .content {
    text-align: center;
    max-width: rem-calc(800);
    margin: 0 auto;
    .headline {
      color: $secondary-color;
    }
    .button {
      margin-top: rem-calc(25);
      @include breakpoint(medium) {
        margin-top: rem-calc(45);
      }
    }
  }
  .page-content-small {
    @include breakpoint(medium) {
      padding-bottom: rem-calc(40);
    }
  }
}
