//  Editorials
//  -----------------------------

.editorial {
  text-align: center;
  h2 {
    margin-bottom: 18px;
    @include breakpoint(large) {
      margin-bottom: 38px;
    }
  }
  p {
    &.signatur {
      margin-top: rem-calc(32);
      font-size: rem-calc(15);
      line-height: 1.5;
      text-transform: uppercase;
      @include breakpoint(medium) {
        margin-top: rem-calc(64);
      }
    }
    &.primary {
      font-weight: 900;
    }
    span {
      font-weight: normal;
    }
  }
  &.aboutus {
    .h1 {
      margin-bottom: 1.5rem;
      @include breakpoint(medium) {
        margin-bottom: 3.5rem;
      }
    }
  }
  &.maintenance,
  &.training {
    h2,
    p {
      max-width: rem-calc(860);
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.cta-action {
    h2,
    p {
      max-width: rem-calc(460);
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    h2 {
      &.headline-font {
        @include museo-900;
        margin-bottom: 15px;
        @include breakpoint(medium) {
          margin-bottom: 35px;
        }
        @include breakpoint(large) {
          margin-bottom: 55px;
        }
      }
    }
    a {
      margin-bottom: 0;
    }
  }
}
