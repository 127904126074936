//  Contact Form
//  -----------------------------

.form-contact {
    .form-headline {
        text-align: center;
        margin-bottom: rem-calc(75);
        h2 {
            margin-bottom: rem-calc(18);
            @include breakpoint(medium) {
                margin-bottom: rem-calc(32);
            }
        }
        p {
            @include lato-heavy;
        }
    }
    .form-content {
        &.submit {
            text-align: center;
        }
        .info-required {
            font-size: .7rem;
        }
    }
    .hidden {
        display: none;
    }
}