//  Site Containers
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global Setup with Sticky Footer
//   2. Page Content

// 1. Global Setup with Sticky Footer
// ---------

html,
body {
  height: 100%;

  .page-wrap {
    min-height: 100%;
    margin-bottom: -$footer-min-height;
    overflow: hidden;

    .push {
      height: $footer-min-height;
    }
  }
}

// 2. Page Content
// ---------
// .page-content {
//     padding: $pagecontent-padding-top $pagecontent-padding-side $pagecontent-padding-bottom;

//     &.with-stage {
//         padding-top: 0;
//     }
// }

.page-container {
  padding-top: 100px;
}

.page-content {
  padding: rem-calc(40 10);
  @include breakpoint(medium) {
    padding: rem-calc(80 40);
  }
  @include breakpoint(large) {
    padding: rem-calc(120 80);
  }
  &.column-block-compensation {
    padding: rem-calc(40 10 10);
    @include breakpoint(medium) {
      padding: rem-calc(80 40 20);
    }
    @include breakpoint(large) {
      padding: rem-calc(120 80 80);
    }
  }
}

.page-content-small {
  padding: rem-calc(42 10);
  @include breakpoint(medium) {
    padding: rem-calc(56 40);
  }
  @include breakpoint(large) {
    padding: rem-calc(80 80);
  }
  &.column-block-compensation {
    padding: rem-calc(42 10 16);
    @include breakpoint(medium) {
      padding: rem-calc(56 40 16);
    }
    @include breakpoint(large) {
      padding: rem-calc(80 80 40);
    }
  }
}

section:not(.slider-fullscreen):not(.bg-primary):not(.bg-secondary):not(.bg-light-gray):not(.bg-medium-gray):not(.bg-dark-gray){
  .page-content {
    padding: rem-calc(0 10 40 10);
    @include breakpoint(medium) {
      padding: rem-calc(0 40 80 40);
    }
    @include breakpoint(large) {
      padding: rem-calc(0 80 120 80);
    }
  }
}

section {
  &.bg-primary,
  &.bg-secondary,
  &.bg-light-gray,
  &.bg-medium-gray,
  &.bg-dark-gray {
    + section {
      &:not(.bg-primary, .bg-secondary, .bg-light-gray, .bg-medium-gray, .bg-dark-gray) {
        .page-content {
          padding: rem-calc(40 10);
          @include breakpoint(medium) {
            padding: rem-calc(80 40);
          }
          @include breakpoint(large) {
            padding: rem-calc(120 80);
          }
        }
      }
    }
  }
}
