//  Icons
//  -----------------------------

.icon {
  &.icon-phone {
    @include image-svg("icons/icn-phone", 18px, 19px);
  }
  &.icon-example-electric {
    @include image-svg("icons/icon-example-electric", 36px, 35px);
  }
}
