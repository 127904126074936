//  Quote Fullwidth
//  -----------------------------

.quote-fullwidth {
    position: relative;
    background-color: #e4032e;
    background-image: radial-gradient(circle, #e5005b 0%, #e4032e 100%);
    &.no-bg {
        background-color: inherit;
        background-image: inherit;
    }
    .img-wrapper {
        max-width: 1520px;
        margin: 0 auto;
        min-height: 210px;
        overflow: hidden;
        background-size: cover;
        background-position: left top;
        background-repeat: no-repeat;
        @include breakpoint(small only) {                
            background-position: 15% 0%;
        }

        &.img-home-1 {
            max-width: 1520px;
            margin: 0 auto;

            background-image: url('../img/0933.jpg'); 
            height: 420px;     
            background-position-y: -145px;
            @include breakpoint(small only) {                
                background-position-y: 0px;
            }
        }
        &.img-services-1 { 
            max-width: 1520px;
            margin: 0 auto;

            background-image: url('../img/0390.jpg'); 
            height: 420px;     
            background-position-y: -110px;
            @include breakpoint(medium only) {                
                background-position-y: -0;
            }
            @include breakpoint(small only) {                
                background-position-y: 0px;
            }
        }
        &.img-maintenance-1 { 
            max-width: 1520px;
            margin: 0 auto;

            background-image: url('../img/0456.jpg'); 
            height: 420px;     
            background-position-y: -560px;
            @media (max-width: 1517px) {
                background-position-y: bottom;
            }
            @include breakpoint(medium only) {                
                background-position-y: bottom;
            }
            @include breakpoint(small only) {                
                background-position-y: bottom;
            }
        }
        &.img-training-1 { 
            max-width: 1520px;
            margin: 0 auto;

            background-image: url('../img/0539.jpg'); 
            height: 420px;     
            background-position-y: -220px;
            @include breakpoint(medium only) {                
                background-position-y: bottom;
            }
            @include breakpoint(small only) {                
                background-position-y: bottom;
            }
        }
        &.img-about-1 { 
            max-width: 1520px;
            margin: 0 auto; 

            background-image: url('../img/0876.jpg'); 
            height: 790px;     
            background-position-y: top;
            @include breakpoint(small only) {    
                background-size: 100%;            
                height: auto;     
                background-position-y: center;
            }
        }
        &.img-team-1 { 
            background-image: url('../img/1061.jpg'); 
            height: 620px;     
            background-position-y: -320px;
            
            // max breite
            max-width: 1920px;
            margin: 0 auto;
            @media (max-width: 1563px) {
                background-size: 100%;     
                background-position-y: center;
            }
            @include breakpoint(medium only) {                
                    height: auto;
                background-size: 100%;     
                background-position-y: center;
            }
            @include breakpoint(small only) {                
                height: auto;
                background-size: 100%;     
                background-position-y: center;
            }
        }
    }
    .quote-content-wrapper {
        background: linear-gradient(#e5005b 0%, #e4032e 100%);
        @include breakpoint(large) {
            position: absolute;
            top: 25%;
            right: 0;
            left:0;
            padding: 0;
            padding-left:5rem;
            padding-right:5rem;
            background: transparent;
        }        
        .quote-txt-box {
            position: relative;
            padding: .8rem 1.875rem 1.875rem;
            background: linear-gradient(#e5005b 0%, #e4032e 100%);
                &:before {
                    content: "";
                    position: absolute;
                    left: 30px;
                    top: -40px;
                    background-image: url("../img/icons/quote-fullwidth-quotation.svg");
                    width: 135px;
                    height: 88px;
                    z-index: 1;
                }
                @include breakpoint(large) {  
                    &:after {
                        content: "";
                        position: absolute;
                        left: -38px;
                        top: 0;
                        width: 38px;
                        height: 50px;
                        border-top: solid 50px #e5005b;
                        border-left: solid 38px transparent;
                        background-color: transparent;
                    }
                }
            p {
                font-family: lato-heavy;
                margin-bottom: 0;
                &.copy-quote {
                    margin-bottom: 1rem;
                    position: relative;
                    z-index: 10;
                }
            }
            h5 {
                position: relative;
                margin-top: 1.5rem;
                margin-bottom: 0;
                line-height: 2rem;
                text-transform: none;
                z-index: 10;
            }
            @include breakpoint(small only) {                
                background: transparent;
            }
        }
    }
}