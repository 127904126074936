//  Mixins
//  -----------------------------
//
//  Table of Contents:
//
//   1. Images
//   2. Placeholder
//   3. Fonts
//   4. SVGs
//   5. Transitions

// 1. Images
// ---------

// Mixin für SVG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include image-svg('logo', 140px, 40px);
@mixin image-svg($image, $width, $height) {
  background-image: url("#{$image-path}/#{$image}.svg");
  background-repeat: no-repeat;
  background-size: $width $height;
  width: $width;
  height: $height;
  display: inline-block;
}

// Mixin für PNG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfad, Breite und Höhe (vom Bildausschnitt); optional Breite und Höhe von der gesamten Grafik
// Beispiel: @include image-png('logo', 140px, 40px);
@mixin image-png(
  $image,
  $width,
  $height,
  $retinaWidth: 100%,
  $retinaHeight: auto
) {
  background-image: url("#{$image-path}/#{$image}.png");
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: inline-block;
  @include retina-image-png($image, $retinaWidth, $retinaHeight);
}

// Mixin für JPG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include image-jpg('logo', 140px, 40px);
@mixin image-jpg($image, $width, $height) {
  background-image: url("#{$image-path}/#{$image}.jpg");
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: inline-block;
  @include retina-image-jpg($image, 100%, auto);
}

// Mixin für Retinadarstellung für PNG-Bild/Icon
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include retina-image-png('logo', 140px, 40px);
@mixin retina-image-png($image, $width, $height) {
  @include breakpoint(retina) {
    background-image: url("#{$retina-image-path}/#{$image}_2x.png");
    background-size: $width $height;
    background-repeat: no-repeat;
  }
}

// Mixin für Retinadarstellung für JPG-Bild/Icon
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include retina-image-jpg('logo', 140px, 40px);
@mixin retina-image-jpg($image, $width, $height) {
  @include breakpoint(retina) {
    background-image: url("#{$retina-image-path}/#{$image}_2x.jpg");
    background-size: $width $height;
    background-repeat: no-repeat;
  }
}

// 2. Placeholder
// ---------

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

// 3. Fonts
// ---------

$fallback_fonts: Helvetica, Arial, sans-serif;

//Example:

@mixin museo-900 {
  font-family: "museo-900", $fallback_fonts;
  font-weight: normal;
}

@mixin museo-700 {
  font-family: "museo-700", $fallback_fonts;
  font-weight: normal;
}

@mixin museo-500 {
  font-family: "museo-500", $fallback_fonts;
  font-weight: normal;
}

@mixin museo-300 {
  font-family: "museo-300", $fallback_fonts;
  font-weight: normal;
}

@mixin lato-reg {
  font-family: "lato-regular", $fallback_fonts;
  font-weight: normal;
}

@mixin lato-heavy {
  font-family: "lato-heavy", $fallback_fonts;
  font-weight: normal;
}

// 4. SVGs
// ---------
@mixin svg-content {
  //Notwendiger Aufbau, damit SVGs bei IE richtig sitzen (wenn SVG als img-Tag hinterlegt wird)
  //Um das SVG muss IMMER ein Wrapper sitzen – dieser gibt die Größe für das SVG vor! Das SVG selbst wird absolut zum Wrapper positioniert

  display: block;
  position: relative; /* create positioning context for svg */
  height: 0; /* collapse the container's height */
  //width: 100vw; /* specify any width you want (a percentage value, basically) */

  //Wichtig! Das Padding gibt das nötige Bildverhältnis des SVG-Bildes an (aspect ratio)
  //(svg height / svg width) * width-value;  //width-value = Das SVG steht im Verhältnis zu dieser Breite, meistens 100% oder 100vw
  //padding-top: calc((40 / 1600) * 100vw);

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// 5. Transitions
// ---------

@mixin trans-bg-color {
  -webkit-transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25 ease-in-out;
  -ms-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
}

@mixin trans-color {
  -webkit-transition: color 0.25s ease-in-out;
  -moz-transition: color 0.25 ease-in-out;
  -ms-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

// 6. Gradients
// ---------

@mixin gradient-primary {
  background: rgb(229, 0, 91);
  background-image: -ms-radial-gradient(
    circle,
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  background-image: -moz-radial-gradient(
    circle,
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  background-image: -webkit-radial-gradient(
    circle,
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  background-image: radial-gradient(
    circle,
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5005b",endColorstr="#e4032e",GradientType=1);
}

@mixin gradient-secondary {
  background: rgb(248, 170, 0);
  background-image: -moz-radial-gradient(
    circle,
    rgba(248, 170, 0, 1) 0%,
    rgba(243, 146, 0, 1) 100%
  );
  background-image: -webkit-radial-gradient(
    circle,
    rgba(248, 170, 0, 1) 0%,
    rgba(243, 146, 0, 1) 100%
  );
  background-image: radial-gradient(
    circle,
    rgba(248, 170, 0, 1) 0%,
    rgba(243, 146, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8aa00",endColorstr="#f39200",GradientType=1);
}

@mixin gradient-primary-linear {
  background: rgb(229, 0, 91);

  background-image: -ms-linear-gradient(
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  background-image: -moz-linear-gradient(
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  background-image: -webkit-linear-gradient(
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  background-image: linear-gradient(
    rgba(229, 0, 91, 1) 0%,
    rgba(228, 3, 46, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5005b",endColorstr="#e4032e",GradientType=1);
}

@mixin gradient-secondary-linear {
  background: rgb(248, 170, 0);

  background-image: -moz-linear-gradient(
    rgba(248, 170, 0, 1) 0%,
    rgba(243, 146, 0, 1) 100%
  );
  background-image: -webkit-linear-gradient(
    rgba(248, 170, 0, 1) 0%,
    rgba(243, 146, 0, 1) 100%
  );
  background-image: linear-gradient(
    rgba(248, 170, 0, 1) 0%,
    rgba(243, 146, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8aa00",endColorstr="#f39200",GradientType=1);
}

@mixin clearfix() {
  &::before,
  &::after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }
}
