//  3 Columns Icon Text
//  -----------------------------

.icn-big-txt-4-columns {
  .h1 {
    margin-bottom: 2.5rem;
    span {
      font-family: museo-500;
    }
    @include breakpoint(medium) {
      margin-bottom: 5.5rem;
    }
  }
  .img-txt-container {
    img {
      margin-bottom: rem-calc(45);
    }
    h4 {
      padding-bottom: rem-calc(15);
      border-bottom: solid 3px $white;
    }
    p {
      font-family: museo-900;
    }
  }
  &.aboutus {
    .page-content {
      &.column-block-compensation {
        padding: rem-calc(40 10 10);
        @include breakpoint(medium) {
          padding: rem-calc(80 40 20);
        }
        @include breakpoint(xxlarge) {
          padding: 120px 80px 80px;
        }
      }
    }
    .large-3 {
      @include breakpoint(large) {
        width: 20%;
      }
      img {
        width: 100%;
        height: auto;
        max-height: 149px;
      }
    }
    .longer-text {
      margin-bottom: 0;
    }
    .mobile-height{
      @media screen and (max-width: 595px) {
        min-height: 350px;
      }
    }
  }
}
