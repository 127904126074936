//  3 Columns Icon Text
//  -----------------------------

.icn-txt-3-columns {
  .h1 {
    text-align: center;
    margin-bottom: 2.5rem;
    span {
      font-family: museo-500;
    }
    @include breakpoint(medium) {
      margin-bottom: 5.5rem;
    }
  }
  .img-txt-container {
    @media only screen and (min-width: 500px) and (max-width: 639px) {
      width: 80%;
      margin: 0 auto;
    }
    img {
      margin-bottom: rem-calc(45);
      @include breakpoint(small only) {
        margin-bottom: rem-calc(30);
      }
    }
    h4 {
      margin-bottom: rem-calc(20);
      padding-bottom: rem-calc(15);
      border-bottom: solid 1px $white;
    }
    .textlink {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }

  .page-content {
    &.column-block-compensation {
      padding: rem-calc(50 10 0);
      @include breakpoint(medium) {
        padding: rem-calc(80 40 0);
      }
      @include breakpoint(large) {
        padding: rem-calc(120 80 40);
      }
    }
  }
  .column-block {
    margin-bottom: 55px;
    @include breakpoint(medium) {
      margin-bottom: 80px;
    }
    &.end {
      clear: both;
      @include breakpoint(large) {
        clear: none;
      }
    }
  }
  &.about-us {
    .h1 {
      position: relative;
      margin-bottom: 40px;
      @include breakpoint(medium) {
        max-width: 1000px;
        margin: 0 auto 40px;
        text-align: center;
      }
    }
    .intro {
      position: relative;
      display: block;
      margin: 0 auto 2.5rem;
      text-align: center;
      @include breakpoint(medium) {
        margin-bottom: 5.5rem;
        max-width: 980px;
        margin: 0 auto 5.5rem;
      }
    }
    .columns {
      &.second-row {
        @include breakpoint(large) {
          clear: both;
        }
      }
      &.end {
        clear: none;
      }
      h5 {
        //@include museo-900;
        line-height: 1.25;
        color: $white;
        margin-bottom: 0;
        padding-bottom: rem-calc(15);
        border-bottom: none;
      }
      .img-txt-container {
        border-bottom: solid 1px $white;
      }
    }
  }
}
